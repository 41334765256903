import React from "react";
import { useDelayedHover } from "../../../hooks/useDelayedHover";
import { Countries } from "../BuildersMap";

interface Props {
  className?: string;
  onHover: (
    country: Countries | undefined
  ) => React.MouseEventHandler<SVGGElement>;
}

const UnitedStatesMap: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { handleMouseEnter, handleMouseOut } = useDelayedHover(
    props.onHover,
    600
  );

  return (
    <g
      id={Countries.UnitedStates}
      onMouseEnter={handleMouseEnter(Countries.UnitedStates)}
      onMouseOut={handleMouseOut}
      fill='url(#paint0_linear_3616_2988)'>
      <path d='M290.828 448.454L287.097 448.418V452.185H290.828V448.454Z' />
      <path d='M233.497 448.454L229.766 448.418V452.185H233.497V448.454Z' />
      <path d='M290.828 440.297L287.097 440.262V444.028H290.828V440.297Z' />
      <path d='M282.637 440.297L278.906 440.262V444.028H282.637V440.297Z' />
      <path d='M241.689 440.297L237.958 440.262V444.028H241.689V440.297Z' />
      <path d='M249.88 440.297L246.149 440.262V444.028H249.88V440.297Z' />
      <path d='M258.073 440.297L254.342 440.262V444.028H258.073V440.297Z' />
      <path d='M233.497 440.297L229.766 440.262V444.028H233.497V440.297Z' />
      <path d='M225.305 440.297L221.574 440.262V444.028H225.305V440.297Z' />
      <path d='M282.637 432.152L278.906 432.117V435.884H282.637V432.152Z' />
      <path d='M290.816 432.152L287.085 432.117V435.884H290.816V432.152Z' />
      <path d='M274.445 432.152L270.714 432.117V435.884H274.445V432.152Z' />
      <path d='M266.265 432.152L262.534 432.117V435.884H266.265V432.152Z' />
      <path d='M258.073 432.152L254.342 432.117V435.884H258.073V432.152Z' />
      <path d='M249.88 432.152L246.149 432.117V435.884H249.88V432.152Z' />
      <path d='M241.689 432.152L237.958 432.117V435.884H241.689V432.152Z' />
      <path d='M233.497 432.152L229.766 432.117V435.884H233.497V432.152Z' />
      <path d='M225.305 432.152L221.574 432.117V435.884H225.305V432.152Z' />
      <path d='M217.114 432.152L213.383 432.117V435.884H217.114V432.152Z' />
      <path d='M299.02 423.996L295.289 423.96V427.727H299.02V423.996Z' />
      <path d='M290.828 423.996L287.097 423.96V427.727H290.828V423.996Z' />
      <path d='M282.637 423.996L278.906 423.96V427.727H282.637V423.996Z' />
      <path d='M274.445 423.996L270.714 423.96V427.727H274.445V423.996Z' />
      <path d='M266.265 423.996L262.534 423.96V427.727H266.265V423.996Z' />
      <path d='M258.073 423.996L254.342 423.96V427.727H258.073V423.996Z' />
      <path d='M249.88 423.996L246.149 423.96V427.727H249.88V423.996Z' />
      <path d='M241.689 423.996L237.958 423.96V427.727H241.689V423.996Z' />
      <path d='M233.497 423.996L229.766 423.96V427.727H233.497V423.996Z' />
      <path d='M225.305 423.996L221.574 423.96V427.727H225.305V423.996Z' />
      <path d='M217.114 423.996L213.383 423.96V427.727H217.114V423.996Z' />
      <path d='M208.934 423.996L205.203 423.96V427.727H208.934V423.996Z' />
      <path d='M200.742 423.996L197.01 423.96V427.727H200.742V423.996Z' />
      <path d='M192.55 423.996L188.819 423.96V427.727H192.55V423.996Z' />
      <path d='M184.359 423.996L180.628 423.96V427.727H184.359V423.996Z' />
      <path d='M176.167 423.996L172.436 423.96V427.727H176.167V423.996Z' />
      <path d='M167.975 423.996L164.244 423.96V427.727H167.975V423.996Z' />
      <path d='M307.212 415.84L303.481 415.804V419.571H307.212V415.84Z' />
      <path d='M299.02 415.84L295.289 415.804V419.571H299.02V415.84Z' />
      <path d='M290.828 415.84L287.097 415.804V419.571H290.828V415.84Z' />
      <path d='M282.637 415.84L278.906 415.804V419.571H282.637V415.84Z' />
      <path d='M274.445 415.84L270.714 415.804V419.571H274.445V415.84Z' />
      <path d='M266.265 415.84L262.534 415.804V419.571H266.265V415.84Z' />
      <path d='M258.073 415.84L254.342 415.804V419.571H258.073V415.84Z' />
      <path d='M249.88 415.84L246.149 415.804V419.571H249.88V415.84Z' />
      <path d='M241.689 415.84L237.958 415.804V419.571H241.689V415.84Z' />
      <path d='M233.497 415.84L229.766 415.804V419.571H233.497V415.84Z' />
      <path d='M225.305 415.84L221.574 415.804V419.571H225.305V415.84Z' />
      <path d='M217.114 415.84L213.383 415.804V419.571H217.114V415.84Z' />
      <path d='M208.934 415.84L205.203 415.804V419.571H208.934V415.84Z' />
      <path d='M200.742 415.84L197.01 415.804V419.571H200.742V415.84Z' />
      <path d='M192.55 415.84L188.819 415.804V419.571H192.55V415.84Z' />
      <path d='M184.359 415.84L180.628 415.804V419.571H184.359V415.84Z' />
      <path d='M176.167 415.84L172.436 415.804V419.571H176.167V415.84Z' />
      <path d='M167.975 415.84L164.244 415.804V419.571H167.975V415.84Z' />
      <path d='M159.784 415.84L156.052 415.804V419.571H159.784V415.84Z' />
      <path d='M307.212 407.683L303.481 407.647V411.414H307.212V407.683Z' />
      <path d='M299.02 407.683L295.289 407.647V411.414H299.02V407.683Z' />
      <path d='M290.828 407.683L287.097 407.647V411.414H290.828V407.683Z' />
      <path d='M282.637 407.683L278.906 407.647V411.414H282.637V407.683Z' />
      <path d='M274.445 407.683L270.714 407.647V411.414H274.445V407.683Z' />
      <path d='M266.265 407.683L262.534 407.647V411.414H266.265V407.683Z' />
      <path d='M258.073 407.683L254.342 407.647V411.414H258.073V407.683Z' />
      <path d='M249.88 407.683L246.149 407.647V411.414H249.88V407.683Z' />
      <path d='M241.689 407.683L237.958 407.647V411.414H241.689V407.683Z' />
      <path d='M233.497 407.683L229.766 407.647V411.414H233.497V407.683Z' />
      <path d='M225.305 407.683L221.574 407.647V411.414H225.305V407.683Z' />
      <path d='M217.114 407.683L213.383 407.647V411.414H217.114V407.683Z' />
      <path d='M208.934 407.683L205.203 407.647V411.414H208.934V407.683Z' />
      <path d='M200.742 407.683L197.01 407.647V411.414H200.742V407.683Z' />
      <path d='M192.55 407.683L188.819 407.647V411.414H192.55V407.683Z' />
      <path d='M184.359 407.683L180.628 407.647V411.414H184.359V407.683Z' />
      <path d='M176.167 407.683L172.436 407.647V411.414H176.167V407.683Z' />
      <path d='M167.975 407.683L164.244 407.647V411.414H167.975V407.683Z' />
      <path d='M159.784 407.683L156.052 407.647V411.414H159.784V407.683Z' />
      <path d='M307.212 399.538L303.481 399.503V403.269H307.212V399.538Z' />
      <path d='M299.02 399.538L295.289 399.503V403.269H299.02V399.538Z' />
      <path d='M290.828 399.538L287.097 399.503V403.269H290.828V399.538Z' />
      <path d='M282.637 399.538L278.906 399.503V403.269H282.637V399.538Z' />
      <path d='M274.445 399.538L270.714 399.503V403.269H274.445V399.538Z' />
      <path d='M266.265 399.538L262.534 399.503V403.269H266.265V399.538Z' />
      <path d='M258.073 399.538L254.342 399.503V403.269H258.073V399.538Z' />
      <path d='M249.88 399.538L246.149 399.503V403.269H249.88V399.538Z' />
      <path d='M241.689 399.538L237.958 399.503V403.269H241.689V399.538Z' />
      <path d='M233.497 399.538L229.766 399.503V403.269H233.497V399.538Z' />
      <path d='M225.305 399.538L221.574 399.503V403.269H225.305V399.538Z' />
      <path d='M217.114 399.538L213.383 399.503V403.269H217.114V399.538Z' />
      <path d='M208.934 399.538L205.203 399.503V403.269H208.934V399.538Z' />
      <path d='M200.742 399.538L197.01 399.503V403.269H200.742V399.538Z' />
      <path d='M192.55 399.538L188.819 399.503V403.269H192.55V399.538Z' />
      <path d='M184.359 399.538L180.628 399.503V403.269H184.359V399.538Z' />
      <path d='M176.167 399.538L172.436 399.503V403.269H176.167V399.538Z' />
      <path d='M167.975 399.538L164.244 399.503V403.269H167.975V399.538Z' />
      <path d='M159.784 399.538L156.052 399.503V403.269H159.784V399.538Z' />
      <path d='M151.603 399.538L147.872 399.503V403.269H151.603V399.538Z' />
      <path d='M323.596 391.381L319.865 391.346V395.113H323.596V391.381Z' />
      <path d='M315.404 391.381L311.673 391.346V395.113H315.404V391.381Z' />
      <path d='M307.212 391.381L303.481 391.346V395.113H307.212V391.381Z' />
      <path d='M299.02 391.381L295.289 391.346V395.113H299.02V391.381Z' />
      <path d='M290.828 391.381L287.097 391.346V395.113H290.828V391.381Z' />
      <path d='M282.637 391.381L278.906 391.346V395.113H282.637V391.381Z' />
      <path d='M274.445 391.381L270.714 391.346V395.113H274.445V391.381Z' />
      <path d='M266.265 391.381L262.534 391.346V395.113H266.265V391.381Z' />
      <path d='M258.073 391.381L254.342 391.346V395.113H258.073V391.381Z' />
      <path d='M249.88 391.381L246.149 391.346V395.113H249.88V391.381Z' />
      <path d='M241.689 391.381L237.958 391.346V395.113H241.689V391.381Z' />
      <path d='M233.497 391.381L229.766 391.346V395.113H233.497V391.381Z' />
      <path d='M225.305 391.381L221.574 391.346V395.113H225.305V391.381Z' />
      <path d='M217.114 391.381L213.383 391.346V395.113H217.114V391.381Z' />
      <path d='M208.934 391.381L205.203 391.346V395.113H208.934V391.381Z' />
      <path d='M200.742 391.381L197.01 391.346V395.113H200.742V391.381Z' />
      <path d='M192.55 391.381L188.819 391.346V395.113H192.55V391.381Z' />
      <path d='M184.359 391.381L180.628 391.346V395.113H184.359V391.381Z' />
      <path d='M176.167 391.381L172.436 391.346V395.113H176.167V391.381Z' />
      <path d='M167.975 391.381L164.244 391.346V395.113H167.975V391.381Z' />
      <path d='M159.784 391.381L156.052 391.346V395.113H159.784V391.381Z' />
      <path d='M151.603 391.381L147.872 391.346V395.113H151.603V391.381Z' />
      <path d='M323.596 383.226L319.865 383.19V386.957H323.596V383.226Z' />
      <path d='M315.404 383.226L311.673 383.19V386.957H315.404V383.226Z' />
      <path d='M307.212 383.226L303.481 383.19V386.957H307.212V383.226Z' />
      <path d='M282.637 383.226L278.906 383.19V386.957H282.637V383.226Z' />
      <path d='M282.637 375.069L278.906 375.034V378.8H282.637V375.069Z' />
      <path d='M274.445 383.226L270.714 383.19V386.957H274.445V383.226Z' />
      <path d='M274.445 375.069L270.714 375.034V378.8H274.445V375.069Z' />
      <path d='M266.265 383.226L262.534 383.19V386.957H266.265V383.226Z' />
      <path d='M258.073 383.226L254.342 383.19V386.957H258.073V383.226Z' />
      <path d='M249.88 383.226L246.149 383.19V386.957H249.88V383.226Z' />
      <path d='M241.689 383.226L237.958 383.19V386.957H241.689V383.226Z' />
      <path d='M233.497 383.226L229.766 383.19V386.957H233.497V383.226Z' />
      <path d='M225.305 383.226L221.574 383.19V386.957H225.305V383.226Z' />
      <path d='M217.114 383.226L213.383 383.19V386.957H217.114V383.226Z' />
      <path d='M208.934 383.226L205.203 383.19V386.957H208.934V383.226Z' />
      <path d='M200.742 383.226L197.01 383.19V386.957H200.742V383.226Z' />
      <path d='M192.55 383.226L188.819 383.19V386.957H192.55V383.226Z' />
      <path d='M184.359 383.226L180.628 383.19V386.957H184.359V383.226Z' />
      <path d='M176.167 383.226L172.436 383.19V386.957H176.167V383.226Z' />
      <path d='M167.975 383.226L164.244 383.19V386.957H167.975V383.226Z' />
      <path d='M159.784 383.226L156.052 383.19V386.957H159.784V383.226Z' />
      <path d='M151.603 383.226L147.872 383.19V386.957H151.603V383.226Z' />
      <path d='M331.775 375.081L328.044 375.045V378.812H331.775V375.081Z' />
      <path d='M323.596 375.081L319.865 375.045V378.812H323.596V375.081Z' />
      <path d='M315.404 375.081L311.673 375.045V378.812H315.404V375.081Z' />
      <path d='M266.265 375.081L262.534 375.045V378.812H266.265V375.081Z' />
      <path d='M258.073 375.081L254.342 375.045V378.812H258.073V375.081Z' />
      <path d='M249.88 375.081L246.149 375.045V378.812H249.88V375.081Z' />
      <path d='M241.689 375.081L237.958 375.045V378.812H241.689V375.081Z' />
      <path d='M233.497 375.081L229.766 375.045V378.812H233.497V375.081Z' />
      <path d='M225.305 375.081L221.574 375.045V378.812H225.305V375.081Z' />
      <path d='M217.114 375.081L213.383 375.045V378.812H217.114V375.081Z' />
      <path d='M208.934 375.081L205.203 375.045V378.812H208.934V375.081Z' />
      <path d='M200.742 375.081L197.01 375.045V378.812H200.742V375.081Z' />
      <path d='M192.55 375.081L188.819 375.045V378.812H192.55V375.081Z' />
      <path d='M184.359 375.081L180.628 375.045V378.812H184.359V375.081Z' />
      <path d='M176.167 375.081L172.436 375.045V378.812H176.167V375.081Z' />
      <path d='M167.975 375.081L164.244 375.045V378.812H167.975V375.081Z' />
      <path d='M159.784 375.081L156.052 375.045V378.812H159.784V375.081Z' />
      <path d='M151.603 375.081L147.872 375.045V378.812H151.603V375.081Z' />
      <path d='M331.775 366.924L328.044 366.889V370.655H331.775V366.924Z' />
      <path d='M266.265 366.924L262.534 366.889V370.655H266.265V366.924Z' />
      <path d='M249.88 366.924L246.149 366.889V370.655H249.88V366.924Z' />
      <path d='M258.073 366.924L254.342 366.889V370.655H258.073V366.924Z' />
      <path d='M241.689 366.924L237.958 366.889V370.655H241.689V366.924Z' />
      <path d='M233.497 366.924L229.766 366.889V370.655H233.497V366.924Z' />
      <path d='M225.305 366.924L221.574 366.889V370.655H225.305V366.924Z' />
      <path d='M217.114 366.924L213.383 366.889V370.655H217.114V366.924Z' />
      <path d='M208.934 366.924L205.203 366.889V370.655H208.934V366.924Z' />
      <path d='M200.742 366.924L197.01 366.889V370.655H200.742V366.924Z' />
      <path d='M192.55 366.924L188.819 366.889V370.655H192.55V366.924Z' />
      <path d='M184.359 366.924L180.628 366.889V370.655H184.359V366.924Z' />
      <path d='M176.167 366.924L172.436 366.889V370.655H176.167V366.924Z' />
      <path d='M167.975 366.924L164.244 366.889V370.655H167.975V366.924Z' />
      <path d='M159.784 366.924L156.052 366.889V370.655H159.784V366.924Z' />
      <path d='M151.603 366.924L147.872 366.889V370.655H151.603V366.924Z' />
      <path d='M258.073 358.768L254.342 358.732V362.499H258.073V358.768Z' />
      <path d='M249.88 358.768L246.149 358.732V362.499H249.88V358.768Z' />
      <path d='M241.689 358.768L237.958 358.732V362.499H241.689V358.768Z' />
      <path d='M233.497 358.768L229.766 358.732V362.499H233.497V358.768Z' />
      <path d='M225.305 358.768L221.574 358.732V362.499H225.305V358.768Z' />
      <path d='M217.114 358.768L213.383 358.732V362.499H217.114V358.768Z' />
      <path d='M208.934 358.768L205.203 358.732V362.499H208.934V358.768Z' />
      <path d='M200.742 358.768L197.01 358.732V362.499H200.742V358.768Z' />
      <path d='M192.55 358.768L188.819 358.732V362.499H192.55V358.768Z' />
      <path d='M184.359 358.768L180.628 358.732V362.499H184.359V358.768Z' />
      <path d='M176.167 358.768L172.436 358.732V362.499H176.167V358.768Z' />
      <path d='M167.975 358.768L164.244 358.732V362.499H167.975V358.768Z' />
      <path d='M159.784 358.768L156.052 358.732V362.499H159.784V358.768Z' />
      <path d='M151.603 358.768L147.872 358.732V362.499H151.603V358.768Z' />
      <path d='M4.17463 334.31L0.443604 334.275V338.041H4.17463V334.31Z' />
      <path d='M127.028 326.153L123.297 326.118V329.885H127.028V326.153Z' />
      <path d='M127.028 318.009L123.297 317.974V321.74H127.028V318.009Z' />
      <path d='M118.836 318.009L115.105 317.974V321.74H118.836V318.009Z' />
      <path d='M28.7498 318.009L25.0188 317.974V321.74H28.7498V318.009Z' />
      <path d='M20.5579 326.153L16.8269 326.118V329.885H20.5579V326.153Z' />
      <path d='M110.644 309.852L106.913 309.817V313.583H110.644V309.852Z' />
      <path d='M53.314 309.852L49.583 309.817V313.583H53.314V309.852Z' />
      <path d='M36.93 309.852L33.199 309.817V313.583H36.93V309.852Z' />
      <path d='M110.644 301.696L106.913 301.661V305.427H110.644V301.696Z' />
      <path d='M45.1219 301.696L41.3909 301.661V305.427H45.1219V301.696Z' />
      <path d='M36.93 301.696L33.199 301.661V305.427H36.93V301.696Z' />
      <path d='M94.2608 293.551L90.5298 293.516V297.282H94.2608V293.551Z' />
      <path d='M86.0804 293.551L82.3494 293.516V297.282H86.0804V293.551Z' />
      <path d='M61.5057 293.551L57.7747 293.516V297.282H61.5057V293.551Z' />
      <path d='M53.314 293.551L49.583 293.516V297.282H53.314V293.551Z' />
      <path d='M45.1219 293.551L41.3909 293.516V297.282H45.1219V293.551Z' />
      <path d='M36.93 293.551L33.199 293.516V297.282H36.93V293.551Z' />
      <path d='M28.7498 293.551L25.0188 293.516V297.282H28.7498V293.551Z' />
      <path d='M20.5579 293.551L16.8269 293.516V297.282H20.5579V293.551Z' />
      <path d='M86.0804 285.395L82.3494 285.359V289.126H86.0804V285.395Z' />
      <path d='M77.8887 285.395L74.1577 285.359V289.126H77.8887V285.395Z' />
      <path d='M69.6969 285.395L65.9658 285.359V289.126H69.6969V285.395Z' />
      <path d='M61.5057 285.395L57.7747 285.359V289.126H61.5057V285.395Z' />
      <path d='M53.314 285.395L49.583 285.359V289.126H53.314V285.395Z' />
      <path d='M45.1219 285.395L41.3909 285.359V289.126H45.1219V285.395Z' />
      <path d='M36.93 285.395L33.199 285.359V289.126H36.93V285.395Z' />
      <path d='M28.7498 285.395L25.0188 285.359V289.126H28.7498V285.395Z' />
      <path d='M20.5579 285.395L16.8269 285.359V289.126H20.5579V285.395Z' />
      <path d='M12.3663 285.395L8.63525 285.359V289.126H12.3663V285.395Z' />
      <path d='M86.0804 277.238L82.3494 277.203V280.969H86.0804V277.238Z' />
      <path d='M77.8887 277.238L74.1577 277.203V280.969H77.8887V277.238Z' />
      <path d='M69.6969 277.238L65.9658 277.203V280.969H69.6969V277.238Z' />
      <path d='M61.5057 277.238L57.7747 277.203V280.969H61.5057V277.238Z' />
      <path d='M53.314 277.238L49.583 277.203V280.969H53.314V277.238Z' />
      <path d='M45.1219 277.238L41.3909 277.203V280.969H45.1219V277.238Z' />
      <path d='M36.93 277.238L33.199 277.203V280.969H36.93V277.238Z' />
      <path d='M28.7498 277.238L25.0188 277.203V280.969H28.7498V277.238Z' />
      <path d='M20.5579 277.238L16.8269 277.203V280.969H20.5579V277.238Z' />
      <path d='M12.3663 277.238L8.63525 277.203V280.969H12.3663V277.238Z' />
      <path d='M86.0804 269.082L82.3494 269.047V272.813H86.0804V269.082Z' />
      <path d='M77.8887 269.082L74.1577 269.047V272.813H77.8887V269.082Z' />
      <path d='M69.6969 269.082L65.9658 269.047V272.813H69.6969V269.082Z' />
      <path d='M61.5057 269.082L57.7747 269.047V272.813H61.5057V269.082Z' />
      <path d='M53.314 269.082L49.583 269.047V272.813H53.314V269.082Z' />
      <path d='M45.1219 269.082L41.3909 269.047V272.813H45.1219V269.082Z' />
      <path d='M36.93 269.082L33.199 269.047V272.813H36.93V269.082Z' />
      <path d='M28.7498 269.082L25.0188 269.047V272.813H28.7498V269.082Z' />
      <path d='M86.0804 260.937L82.3494 260.902V264.668H86.0804V260.937Z' />
      <path d='M77.8887 260.937L74.1577 260.902V264.668H77.8887V260.937Z' />
      <path d='M69.6969 260.937L65.9658 260.902V264.668H69.6969V260.937Z' />
      <path d='M61.5057 260.937L57.7747 260.902V264.668H61.5057V260.937Z' />
      <path d='M53.314 260.937L49.583 260.902V264.668H53.314V260.937Z' />
      <path d='M45.1219 260.937L41.3909 260.902V264.668H45.1219V260.937Z' />
      <path d='M36.93 260.937L33.199 260.902V264.668H36.93V260.937Z' />
      <path d='M28.7498 260.937L25.0188 260.902V264.668H28.7498V260.937Z' />
      <path d='M20.5579 260.937L16.8269 260.902V264.668H20.5579V260.937Z' />
      <path d='M12.3663 260.937L8.63525 260.902V264.668H12.3663V260.937Z' />
      <path d='M86.0804 252.781L82.3494 252.746V256.512H86.0804V252.781Z' />
      <path d='M77.8887 252.781L74.1577 252.746V256.512H77.8887V252.781Z' />
      <path d='M69.6969 252.781L65.9658 252.746V256.512H69.6969V252.781Z' />
      <path d='M61.5057 252.781L57.7747 252.746V256.512H61.5057V252.781Z' />
      <path d='M53.314 252.781L49.583 252.746V256.512H53.314V252.781Z' />
      <path d='M45.1219 252.781L41.3909 252.746V256.512H45.1219V252.781Z' />
      <path d='M36.93 252.781L33.199 252.746V256.512H36.93V252.781Z' />
      <path d='M28.7498 252.781L25.0188 252.746V256.512H28.7498V252.781Z' />
      <path d='M20.5579 252.781L16.8269 252.746V256.512H20.5579V252.781Z' />
      <path d='M12.3663 252.781L8.63525 252.746V256.512H12.3663V252.781Z' />
      <path d='M86.0804 244.624L82.3494 244.589V248.355H86.0804V244.624Z' />
      <path d='M77.8887 244.624L74.1577 244.589V248.355H77.8887V244.624Z' />
      <path d='M69.6969 244.624L65.9658 244.589V248.355H69.6969V244.624Z' />
      <path d='M61.5057 244.624L57.7747 244.589V248.355H61.5057V244.624Z' />
      <path d='M53.314 244.624L49.583 244.589V248.355H53.314V244.624Z' />
      <path d='M45.1219 244.624L41.3909 244.589V248.355H45.1219V244.624Z' />
      <path d='M36.93 244.624L33.199 244.589V248.355H36.93V244.624Z' />
      <path d='M28.7498 244.624L25.0188 244.589V248.355H28.7498V244.624Z' />
      <path d='M86.0804 236.479L82.3494 236.444V240.21H86.0804V236.479Z' />
      <path d='M77.8887 236.479L74.1577 236.444V240.21H77.8887V236.479Z' />
      <path d='M69.6969 236.479L65.9658 236.444V240.21H69.6969V236.479Z' />
      <path d='M61.5057 236.479L57.7747 236.444V240.21H61.5057V236.479Z' />
      <path d='M53.314 236.479L49.583 236.444V240.21H53.314V236.479Z' />
      <path d='M45.1219 236.479L41.3909 236.444V240.21H45.1219V236.479Z' />
      <path d='M36.93 236.479L33.199 236.444V240.21H36.93V236.479Z' />
      <path d='M28.7498 236.479L25.0188 236.444V240.21H28.7498V236.479Z' />
      <path d='M20.5579 236.479L16.8269 236.444V240.21H20.5579V236.479Z' />
      <path d='M86.0804 228.323L82.3494 228.288V232.054H86.0804V228.323Z' />
      <path d='M77.8887 228.323L74.1577 228.288V232.054H77.8887V228.323Z' />
      <path d='M69.6969 228.323L65.9658 228.288V232.054H69.6969V228.323Z' />
      <path d='M61.5057 228.323L57.7747 228.288V232.054H61.5057V228.323Z' />
      <path d='M53.314 228.323L49.583 228.288V232.054H53.314V228.323Z' />
      <path d='M45.1219 228.323L41.3909 228.288V232.054H45.1219V228.323Z' />
      <path d='M36.93 228.323L33.199 228.288V232.054H36.93V228.323Z' />
      <path d='M28.7498 228.323L25.0188 228.288V232.054H28.7498V228.323Z' />
      <path d='M20.5579 228.323L16.8269 228.288V232.054H20.5579V228.323Z' />
      <path d='M12.3663 228.323L8.63525 228.288V232.054H12.3663V228.323Z' />
      <path d='M86.0804 220.167L82.3494 220.131V223.898H86.0804V220.167Z' />
      <path d='M77.8887 220.167L74.1577 220.131V223.898H77.8887V220.167Z' />
      <path d='M86.0804 212.01L82.3494 211.975V215.741H86.0804V212.01Z' />
      <path d='M77.8887 212.01L74.1577 211.975V215.741H77.8887V212.01Z' />
      <path d='M69.6969 220.167L65.9658 220.131V223.898H69.6969V220.167Z' />
      <path d='M61.5057 220.167L57.7747 220.131V223.898H61.5057V220.167Z' />
      <path d='M53.314 220.167L49.583 220.131V223.898H53.314V220.167Z' />
      <path d='M45.1219 220.167L41.3909 220.131V223.898H45.1219V220.167Z' />
      <path d='M36.93 220.167L33.199 220.131V223.898H36.93V220.167Z' />
      <path d='M28.7498 220.167L25.0188 220.131V223.898H28.7498V220.167Z' />
      <path d='M20.5579 220.167L16.8269 220.131V223.898H20.5579V220.167Z' />
      <path d='M69.6969 212.01L65.9658 211.975V215.741H69.6969V212.01Z' />
      <path d='M61.5057 212.01L57.7747 211.975V215.741H61.5057V212.01Z' />
      <path d='M53.314 212.01L49.583 211.975V215.741H53.314V212.01Z' />
      <path d='M45.1219 212.01L41.3909 211.975V215.741H45.1219V212.01Z' />
      <path d='M36.93 212.01L33.199 211.975V215.741H36.93V212.01Z' />
      <path d='M28.7498 212.01L25.0188 211.975V215.741H28.7498V212.01Z' />
      <path d='M36.93 203.865L33.199 203.83V207.596H36.93V203.865Z' />
      <path d='M45.1219 203.865L41.3909 203.83V207.596H45.1219V203.865Z' />
      <path d='M118.836 309.852L115.105 309.817V313.583H118.836V309.852Z' />
      <path d='M45.1219 472.911L41.3909 472.876V476.642H45.1219V472.911Z' />

      <path d='M364.543 358.768L360.812 358.732V362.499H364.543V358.768Z' />
      <path d='M339.967 358.768L336.236 358.732V362.499H339.967V358.768Z' />
      <path d='M331.775 358.768L328.044 358.732V362.499H331.775V358.768Z' />
      <path d='M323.596 358.768L319.865 358.732V362.499H323.596V358.768Z' />
      <path d='M372.735 350.611L369.004 350.576V354.342H372.735V350.611Z' />
      <path d='M331.775 350.611L328.044 350.576V354.342H331.775V350.611Z' />
      <path d='M348.159 350.611L344.428 350.576V354.342H348.159V350.611Z' />
      <path d='M323.596 350.611L319.865 350.576V354.342H323.596V350.611Z' />
      <path d='M372.735 342.466L369.004 342.431V346.198H372.735V342.466Z' />
      <path d='M364.543 342.466L360.812 342.431V346.198H364.543V342.466Z' />
      <path d='M356.351 342.466L352.62 342.431V346.198H356.351V342.466Z' />
      <path d='M348.159 342.466L344.428 342.431V346.198H348.159V342.466Z' />
      <path d='M339.967 342.466L336.236 342.431V346.198H339.967V342.466Z' />
      <path d='M331.775 342.466L328.044 342.431V346.198H331.775V342.466Z' />
      <path d='M323.596 342.466L319.865 342.431V346.198H323.596V342.466Z' />
      <path d='M372.735 334.31L369.004 334.275V338.041H372.735V334.31Z' />
      <path d='M364.543 334.31L360.812 334.275V338.041H364.543V334.31Z' />
      <path d='M356.351 334.31L352.62 334.275V338.041H356.351V334.31Z' />
      <path d='M348.159 334.31L344.428 334.275V338.041H348.159V334.31Z' />
      <path d='M339.967 334.31L336.236 334.275V338.041H339.967V334.31Z' />
      <path d='M331.775 334.31L328.044 334.275V338.041H331.775V334.31Z' />
      <path d='M323.596 334.31L319.865 334.275V338.041H323.596V334.31Z' />
      <path d='M364.543 326.153L360.812 326.118V329.885H364.543V326.153Z' />
      <path d='M356.351 326.153L352.62 326.118V329.885H356.351V326.153Z' />
      <path d='M348.159 326.153L344.428 326.118V329.885H348.159V326.153Z' />
      <path d='M339.967 326.153L336.236 326.118V329.885H339.967V326.153Z' />
      <path d='M331.775 326.153L328.044 326.118V329.885H331.775V326.153Z' />
      <path d='M323.596 326.153L319.865 326.118V329.885H323.596V326.153Z' />
      <path d='M356.351 318.009L352.62 317.974V321.74H356.351V318.009Z' />
      <path d='M348.159 318.009L344.428 317.974V321.74H348.159V318.009Z' />
      <path d='M339.967 318.009L336.236 317.974V321.74H339.967V318.009Z' />
      <path d='M331.775 318.009L328.044 317.974V321.74H331.775V318.009Z' />
      <path d='M323.596 318.009L319.865 317.974V321.74H323.596V318.009Z' />
      <path d='M348.159 309.852L344.428 309.817V313.583H348.159V309.852Z' />
      <path d='M339.967 309.852L336.236 309.817V313.583H339.967V309.852Z' />
      <path d='M331.775 309.852L328.044 309.817V313.583H331.775V309.852Z' />
      <path d='M323.596 309.852L319.865 309.817V313.583H323.596V309.852Z' />
      <path d='M348.159 301.696L344.428 301.661V305.427H348.159V301.696Z' />
      <path d='M339.967 301.696L336.236 301.661V305.427H339.967V301.696Z' />
      <path d='M348.159 293.551L344.428 293.516V297.282H348.159V293.551Z' />
      <path d='M331.775 301.696L328.044 301.661V305.427H331.775V301.696Z' />
      <path d='M323.596 301.696L319.865 301.661V305.427H323.596V301.696Z' />
      <path d='M323.596 293.551L319.865 293.516V297.282H323.596V293.551Z' />
      <path d='M323.596 285.395L319.865 285.359V289.126H323.596V285.395Z' />
      <path d='M331.775 277.238L328.044 277.203V280.969H331.775V277.238Z' />
      <path d='M339.967 269.082L336.236 269.046V272.813H339.967V269.082Z' />
      <path d='M331.775 269.082L328.044 269.046V272.813H331.775V269.082Z' />
      <path d='M323.596 269.082L319.865 269.046V272.813H323.596V269.082Z' />
      <path d='M339.967 260.937L336.236 260.901V264.668H339.967V260.937Z' />
      <path d='M331.775 260.937L328.044 260.901V264.668H331.775V260.937Z' />
      <path d='M323.596 260.937L319.865 260.901V264.668H323.596V260.937Z' />

      <path d='M339.967 366.924L336.236 366.889V370.655H339.967V366.924Z' />
      <path d='M323.596 366.924L319.865 366.889V370.655H323.596V366.924Z' />
      <path d='M315.404 366.924L311.673 366.889V370.655H315.404V366.924Z' />
      <path d='M307.212 366.924L303.481 366.889V370.655H307.212V366.924Z' />
      <path d='M299.021 366.924L295.29 366.889V370.655H299.021V366.924Z' />
      <path d='M290.829 366.924L287.098 366.889V370.655H290.829V366.924Z' />
      <path d='M282.637 366.924L278.906 366.889V370.655H282.637V366.924Z' />
      <path d='M380.926 358.768L377.195 358.732V362.499H380.926V358.768Z' />
      <path d='M315.404 358.768L311.673 358.732V362.499H315.404V358.768Z' />
      <path d='M307.212 358.768L303.481 358.732V362.499H307.212V358.768Z' />
      <path d='M299.021 358.768L295.29 358.732V362.499H299.021V358.768Z' />
      <path d='M290.829 358.768L287.098 358.732V362.499H290.829V358.768Z' />
      <path d='M282.637 358.768L278.906 358.732V362.499H282.637V358.768Z' />
      <path d='M274.445 358.768L270.714 358.732V362.499H274.445V358.768Z' />
      <path d='M315.404 350.611L311.673 350.576V354.342H315.404V350.611Z' />
      <path d='M307.212 350.611L303.481 350.576V354.342H307.212V350.611Z' />
      <path d='M299.021 350.611L295.29 350.576V354.342H299.021V350.611Z' />
      <path d='M290.829 350.611L287.098 350.576V354.342H290.829V350.611Z' />
      <path d='M282.637 350.611L278.906 350.576V354.342H282.637V350.611Z' />
      <path d='M274.445 350.611L270.714 350.576V354.342H274.445V350.611Z' />
      <path d='M266.265 350.611L262.534 350.576V354.342H266.265V350.611Z' />
      <path d='M258.073 350.611L254.342 350.576V354.342H258.073V350.611Z' />
      <path d='M249.882 350.611L246.151 350.576V354.342H249.882V350.611Z' />
      <path d='M241.689 350.611L237.958 350.576V354.342H241.689V350.611Z' />
      <path d='M233.497 350.611L229.766 350.576V354.342H233.497V350.611Z' />
      <path d='M225.306 350.611L221.575 350.576V354.342H225.306V350.611Z' />
      <path d='M217.114 350.611L213.383 350.576V354.342H217.114V350.611Z' />
      <path d='M208.935 350.611L205.204 350.576V354.342H208.935V350.611Z' />
      <path d='M200.743 350.611L197.012 350.576V354.342H200.743V350.611Z' />
      <path d='M192.55 350.611L188.819 350.576V354.342H192.55V350.611Z' />
      <path d='M184.359 350.611L180.628 350.576V354.342H184.359V350.611Z' />
      <path d='M176.167 350.611L172.436 350.576V354.342H176.167V350.611Z' />
      <path d='M167.975 350.611L164.244 350.576V354.342H167.975V350.611Z' />
      <path d='M159.784 350.611L156.053 350.576V354.342H159.784V350.611Z' />
      <path d='M151.604 350.611L147.873 350.576V354.342H151.604V350.611Z' />
      <path d='M143.411 350.611L139.68 350.576V354.342H143.411V350.611Z' />
      <path d='M315.404 342.466L311.673 342.431V346.198H315.404V342.466Z' />
      <path d='M307.212 342.466L303.481 342.431V346.198H307.212V342.466Z' />
      <path d='M299.021 342.466L295.29 342.431V346.198H299.021V342.466Z' />
      <path d='M290.829 342.466L287.098 342.431V346.198H290.829V342.466Z' />
      <path d='M282.637 342.466L278.906 342.431V346.198H282.637V342.466Z' />
      <path d='M274.445 342.466L270.714 342.431V346.198H274.445V342.466Z' />
      <path d='M266.265 342.466L262.534 342.431V346.198H266.265V342.466Z' />
      <path d='M258.073 342.466L254.342 342.431V346.198H258.073V342.466Z' />
      <path d='M249.882 342.466L246.151 342.431V346.198H249.882V342.466Z' />
      <path d='M241.689 342.466L237.958 342.431V346.198H241.689V342.466Z' />
      <path d='M233.497 342.466L229.766 342.431V346.198H233.497V342.466Z' />
      <path d='M225.306 342.466L221.575 342.431V346.198H225.306V342.466Z' />
      <path d='M217.114 342.466L213.383 342.431V346.198H217.114V342.466Z' />
      <path d='M208.935 342.466L205.204 342.431V346.198H208.935V342.466Z' />
      <path d='M200.743 342.466L197.012 342.431V346.198H200.743V342.466Z' />
      <path d='M192.55 342.466L188.819 342.431V346.198H192.55V342.466Z' />
      <path d='M184.359 342.466L180.628 342.431V346.198H184.359V342.466Z' />
      <path d='M176.167 342.466L172.436 342.431V346.198H176.167V342.466Z' />
      <path d='M167.975 342.466L164.244 342.431V346.198H167.975V342.466Z' />
      <path d='M159.784 342.466L156.053 342.431V346.198H159.784V342.466Z' />
      <path d='M151.604 342.466L147.873 342.431V346.198H151.604V342.466Z' />
      <path d='M143.411 342.466L139.68 342.431V346.198H143.411V342.466Z' />
      <path d='M135.22 342.466L131.489 342.431V346.198H135.22V342.466Z' />
      <path d='M315.404 334.31L311.673 334.275V338.041H315.404V334.31Z' />
      <path d='M307.212 334.31L303.481 334.275V338.041H307.212V334.31Z' />
      <path d='M299.021 334.31L295.29 334.275V338.041H299.021V334.31Z' />
      <path d='M282.637 334.31L278.906 334.275V338.041H282.637V334.31Z' />
      <path d='M274.445 334.31L270.714 334.275V338.041H274.445V334.31Z' />
      <path d='M266.265 334.31L262.534 334.275V338.041H266.265V334.31Z' />
      <path d='M258.073 334.31L254.342 334.275V338.041H258.073V334.31Z' />
      <path d='M249.882 334.31L246.151 334.275V338.041H249.882V334.31Z' />
      <path d='M241.689 334.31L237.958 334.275V338.041H241.689V334.31Z' />
      <path d='M233.497 334.31L229.766 334.275V338.041H233.497V334.31Z' />
      <path d='M225.306 334.31L221.575 334.275V338.041H225.306V334.31Z' />
      <path d='M217.114 334.31L213.383 334.275V338.041H217.114V334.31Z' />
      <path d='M208.935 334.31L205.204 334.275V338.041H208.935V334.31Z' />
      <path d='M200.743 334.31L197.012 334.275V338.041H200.743V334.31Z' />
      <path d='M192.55 334.31L188.819 334.275V338.041H192.55V334.31Z' />
      <path d='M184.359 334.31L180.628 334.275V338.041H184.359V334.31Z' />
      <path d='M176.167 334.31L172.436 334.275V338.041H176.167V334.31Z' />
      <path d='M167.975 334.31L164.244 334.275V338.041H167.975V334.31Z' />
      <path d='M159.784 334.31L156.053 334.275V338.041H159.784V334.31Z' />
      <path d='M151.604 334.31L147.873 334.275V338.041H151.604V334.31Z' />
      <path d='M143.411 334.31L139.68 334.275V338.041H143.411V334.31Z' />
      <path d='M135.22 334.31L131.489 334.275V338.041H135.22V334.31Z' />
      <path d='M118.836 334.31L115.105 334.275V338.041H118.836V334.31Z' />
      <path d='M315.404 326.153L311.673 326.118V329.885H315.404V326.153Z' />
      <path d='M307.212 326.153L303.481 326.118V329.885H307.212V326.153Z' />
      <path d='M299.021 326.153L295.29 326.118V329.885H299.021V326.153Z' />
      <path d='M282.637 326.153L278.906 326.118V329.885H282.637V326.153Z' />
      <path d='M274.445 326.153L270.714 326.118V329.885H274.445V326.153Z' />
      <path d='M266.265 326.153L262.534 326.118V329.885H266.265V326.153Z' />
      <path d='M258.073 326.153L254.342 326.118V329.885H258.073V326.153Z' />
      <path d='M249.882 326.153L246.151 326.118V329.885H249.882V326.153Z' />
      <path d='M241.689 326.153L237.958 326.118V329.885H241.689V326.153Z' />
      <path d='M233.497 326.153L229.766 326.118V329.885H233.497V326.153Z' />
      <path d='M225.306 326.153L221.575 326.118V329.885H225.306V326.153Z' />
      <path d='M217.114 326.153L213.383 326.118V329.885H217.114V326.153Z' />
      <path d='M208.935 326.153L205.204 326.118V329.885H208.935V326.153Z' />
      <path d='M200.743 326.153L197.012 326.118V329.885H200.743V326.153Z' />
      <path d='M192.55 326.153L188.819 326.118V329.885H192.55V326.153Z' />
      <path d='M184.359 326.153L180.628 326.118V329.885H184.359V326.153Z' />
      <path d='M176.167 326.153L172.436 326.118V329.885H176.167V326.153Z' />
      <path d='M167.975 326.153L164.244 326.118V329.885H167.975V326.153Z' />
      <path d='M159.784 326.153L156.053 326.118V329.885H159.784V326.153Z' />
      <path d='M151.604 326.153L147.873 326.118V329.885H151.604V326.153Z' />
      <path d='M143.411 326.153L139.68 326.118V329.885H143.411V326.153Z' />
      <path d='M135.22 326.153L131.489 326.118V329.885H135.22V326.153Z' />
      <path d='M315.404 318.009L311.673 317.974V321.74H315.404V318.009Z' />
      <path d='M307.212 318.009L303.481 317.974V321.74H307.212V318.009Z' />
      <path d='M266.265 318.009L262.534 317.974V321.74H266.265V318.009Z' />
      <path d='M258.073 318.009L254.342 317.974V321.74H258.073V318.009Z' />
      <path d='M249.882 318.009L246.151 317.974V321.74H249.882V318.009Z' />
      <path d='M241.689 318.009L237.958 317.974V321.74H241.689V318.009Z' />
      <path d='M233.497 318.009L229.766 317.974V321.74H233.497V318.009Z' />
      <path d='M225.306 318.009L221.575 317.974V321.74H225.306V318.009Z' />
      <path d='M217.114 318.009L213.383 317.974V321.74H217.114V318.009Z' />
      <path d='M208.935 318.009L205.204 317.974V321.74H208.935V318.009Z' />
      <path d='M200.743 318.009L197.012 317.974V321.74H200.743V318.009Z' />
      <path d='M192.55 318.009L188.819 317.974V321.74H192.55V318.009Z' />
      <path d='M184.359 318.009L180.628 317.974V321.74H184.359V318.009Z' />
      <path d='M176.167 318.009L172.436 317.974V321.74H176.167V318.009Z' />
      <path d='M167.975 318.009L164.244 317.974V321.74H167.975V318.009Z' />
      <path d='M159.784 318.009L156.053 317.974V321.74H159.784V318.009Z' />
      <path d='M151.604 318.009L147.873 317.974V321.74H151.604V318.009Z' />
      <path d='M143.411 318.009L139.68 317.974V321.74H143.411V318.009Z' />
      <path d='M135.22 318.009L131.489 317.974V321.74H135.22V318.009Z' />
      <path d='M315.404 309.852L311.673 309.817V313.583H315.404V309.852Z' />
      <path d='M307.212 309.852L303.481 309.817V313.583H307.212V309.852Z' />
      <path d='M249.882 309.852L246.151 309.817V313.583H249.882V309.852Z' />
      <path d='M258.073 309.852L254.342 309.817V313.583H258.073V309.852Z' />
      <path d='M241.689 309.852L237.958 309.817V313.583H241.689V309.852Z' />
      <path d='M233.497 309.852L229.766 309.817V313.583H233.497V309.852Z' />
      <path d='M225.306 309.852L221.575 309.817V313.583H225.306V309.852Z' />
      <path d='M217.114 309.852L213.383 309.817V313.583H217.114V309.852Z' />
      <path d='M208.935 309.852L205.204 309.817V313.583H208.935V309.852Z' />
      <path d='M200.743 309.852L197.012 309.817V313.583H200.743V309.852Z' />
      <path d='M192.55 309.852L188.819 309.817V313.583H192.55V309.852Z' />
      <path d='M184.359 309.852L180.628 309.817V313.583H184.359V309.852Z' />
      <path d='M176.167 309.852L172.436 309.817V313.583H176.167V309.852Z' />
      <path d='M167.975 309.852L164.244 309.817V313.583H167.975V309.852Z' />
      <path d='M159.784 309.852L156.053 309.817V313.583H159.784V309.852Z' />
      <path d='M151.604 309.852L147.873 309.817V313.583H151.604V309.852Z' />
      <path d='M143.411 309.852L139.68 309.817V313.583H143.411V309.852Z' />
      <path d='M135.22 309.852L131.489 309.817V313.583H135.22V309.852Z' />
      <path d='M127.028 309.852L123.297 309.817V313.583H127.028V309.852Z' />
      <path d='M315.404 301.696L311.673 301.661V305.427H315.404V301.696Z' />
      <path d='M307.212 301.696L303.481 301.661V305.427H307.212V301.696Z' />
      <path d='M299.032 301.696L295.301 301.661V305.427H299.032V301.696Z' />
      <path d='M241.689 301.696L237.958 301.661V305.427H241.689V301.696Z' />
      <path d='M249.882 301.696L246.151 301.661V305.427H249.882V301.696Z' />
      <path d='M233.497 301.696L229.766 301.661V305.427H233.497V301.696Z' />
      <path d='M225.306 301.696L221.575 301.661V305.427H225.306V301.696Z' />
      <path d='M217.114 301.696L213.383 301.661V305.427H217.114V301.696Z' />
      <path d='M208.935 301.696L205.204 301.661V305.427H208.935V301.696Z' />
      <path d='M200.743 301.696L197.012 301.661V305.427H200.743V301.696Z' />
      <path d='M192.55 301.696L188.819 301.661V305.427H192.55V301.696Z' />
      <path d='M184.359 301.696L180.628 301.661V305.427H184.359V301.696Z' />
      <path d='M176.167 301.696L172.436 301.661V305.427H176.167V301.696Z' />
      <path d='M167.975 301.696L164.244 301.661V305.427H167.975V301.696Z' />
      <path d='M159.784 301.696L156.053 301.661V305.427H159.784V301.696Z' />
      <path d='M151.604 301.696L147.873 301.661V305.427H151.604V301.696Z' />
      <path d='M143.411 301.696L139.68 301.661V305.427H143.411V301.696Z' />
      <path d='M135.22 301.696L131.489 301.661V305.427H135.22V301.696Z' />
      <path d='M127.028 301.696L123.297 301.661V305.427H127.028V301.696Z' />
      <path d='M118.836 301.696L115.105 301.661V305.427H118.836V301.696Z' />
      <path d='M315.404 293.551L311.673 293.516V297.282H315.404V293.551Z' />
      <path d='M307.212 293.551L303.481 293.516V297.282H307.212V293.551Z' />
      <path d='M241.689 293.551L237.958 293.516V297.282H241.689V293.551Z' />
      <path d='M233.497 293.551L229.766 293.516V297.282H233.497V293.551Z' />
      <path d='M225.306 293.551L221.575 293.516V297.282H225.306V293.551Z' />
      <path d='M217.114 293.551L213.383 293.516V297.282H217.114V293.551Z' />
      <path d='M208.935 293.551L205.204 293.516V297.282H208.935V293.551Z' />
      <path d='M200.743 293.551L197.012 293.516V297.282H200.743V293.551Z' />
      <path d='M192.55 293.551L188.819 293.516V297.282H192.55V293.551Z' />
      <path d='M184.359 293.551L180.628 293.516V297.282H184.359V293.551Z' />
      <path d='M176.167 293.551L172.436 293.516V297.282H176.167V293.551Z' />
      <path d='M167.975 293.551L164.244 293.516V297.282H167.975V293.551Z' />
      <path d='M159.784 293.551L156.053 293.516V297.282H159.784V293.551Z' />
      <path d='M151.604 293.551L147.873 293.516V297.282H151.604V293.551Z' />
      <path d='M143.411 293.551L139.68 293.516V297.282H143.411V293.551Z' />
      <path d='M135.22 293.551L131.489 293.516V297.282H135.22V293.551Z' />
      <path d='M127.028 293.551L123.297 293.516V297.282H127.028V293.551Z' />
      <path d='M118.836 293.551L115.105 293.516V297.282H118.836V293.551Z' />
      <path d='M110.644 293.551L106.913 293.516V297.282H110.644V293.551Z' />
      <path d='M102.453 293.551L98.7217 293.516V297.282H102.453V293.551Z' />
    </g>
  );
};
export default UnitedStatesMap;
