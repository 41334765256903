import React from "react";
import { Countries } from "../BuildersMap";
import { useDelayedHover } from "../../../hooks/useDelayedHover";

interface Props {
  className?: string;
  onHover: (
    country: Countries | undefined
  ) => React.MouseEventHandler<SVGGElement>;
}

const CanadaMap: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { handleMouseEnter, handleMouseOut } = useDelayedHover(
    props.onHover,
    600
  );

  return (
    <g
      id={Countries.Canada}
      onMouseEnter={handleMouseEnter(Countries.Canada)}
      onMouseOut={handleMouseOut}
      fill='url(#paint0_linear_3616_2988)'>
      <path d='M315.404 285.395L311.673 285.359V289.126H315.404V285.395Z' />
      <path d='M315.404 277.238L311.673 277.203V280.969H315.404V277.238Z' />
      <path d='M307.212 285.395L303.481 285.359V289.126H307.212V285.395Z' />
      <path d='M241.689 285.395L237.958 285.359V289.126H241.689V285.395Z' />
      <path d='M233.497 285.395L229.766 285.359V289.126H233.497V285.395Z' />
      <path d='M225.306 285.395L221.575 285.359V289.126H225.306V285.395Z' />
      <path d='M217.114 285.395L213.383 285.359V289.126H217.114V285.395Z' />
      <path d='M208.935 285.395L205.204 285.359V289.126H208.935V285.395Z' />
      <path d='M200.743 285.395L197.012 285.359V289.126H200.743V285.395Z' />
      <path d='M192.55 285.395L188.819 285.359V289.126H192.55V285.395Z' />
      <path d='M184.359 285.395L180.628 285.359V289.126H184.359V285.395Z' />
      <path d='M176.167 285.395L172.436 285.359V289.126H176.167V285.395Z' />
      <path d='M167.975 285.395L164.244 285.359V289.126H167.975V285.395Z' />
      <path d='M159.784 285.395L156.053 285.359V289.126H159.784V285.395Z' />
      <path d='M151.604 285.395L147.873 285.359V289.126H151.604V285.395Z' />
      <path d='M143.411 285.395L139.68 285.359V289.126H143.411V285.395Z' />
      <path d='M135.22 285.395L131.489 285.359V289.126H135.22V285.395Z' />
      <path d='M127.028 285.395L123.297 285.359V289.126H127.028V285.395Z' />
      <path d='M118.836 285.395L115.105 285.359V289.126H118.836V285.395Z' />
      <path d='M110.644 285.395L106.913 285.359V289.126H110.644V285.395Z' />
      <path d='M102.453 285.395L98.7217 285.359V289.126H102.453V285.395Z' />
      <path d='M94.2608 285.395L90.5298 285.359V289.126H94.2608V285.395Z' />
      <path d='M307.212 277.238L303.481 277.203V280.969H307.212V277.238Z' />
      <path d='M282.637 277.238L278.906 277.203V280.969H282.637V277.238Z' />
      <path d='M249.882 277.238L246.151 277.203V280.969H249.882V277.238Z' />
      <path d='M241.689 277.238L237.958 277.203V280.969H241.689V277.238Z' />
      <path d='M233.497 277.238L229.766 277.203V280.969H233.497V277.238Z' />
      <path d='M225.306 277.238L221.575 277.203V280.969H225.306V277.238Z' />
      <path d='M217.114 277.238L213.383 277.203V280.969H217.114V277.238Z' />
      <path d='M208.935 277.238L205.204 277.203V280.969H208.935V277.238Z' />
      <path d='M200.743 277.238L197.012 277.203V280.969H200.743V277.238Z' />
      <path d='M192.55 277.238L188.819 277.203V280.969H192.55V277.238Z' />
      <path d='M184.359 277.238L180.628 277.203V280.969H184.359V277.238Z' />
      <path d='M176.167 277.238L172.436 277.203V280.969H176.167V277.238Z' />
      <path d='M167.975 277.238L164.244 277.203V280.969H167.975V277.238Z' />
      <path d='M159.784 277.238L156.053 277.203V280.969H159.784V277.238Z' />
      <path d='M151.604 277.238L147.873 277.203V280.969H151.604V277.238Z' />
      <path d='M143.411 277.238L139.68 277.203V280.969H143.411V277.238Z' />
      <path d='M135.22 277.238L131.489 277.203V280.969H135.22V277.238Z' />
      <path d='M127.028 277.238L123.297 277.203V280.969H127.028V277.238Z' />
      <path d='M118.836 277.238L115.105 277.203V280.969H118.836V277.238Z' />
      <path d='M110.644 277.238L106.913 277.203V280.969H110.644V277.238Z' />
      <path d='M102.453 277.238L98.7217 277.203V280.969H102.453V277.238Z' />
      <path d='M94.2608 277.238L90.5298 277.203V280.969H94.2608V277.238Z' />
      <path d='M258.073 269.082L254.342 269.046V272.813H258.073V269.082Z' />
      <path d='M249.882 269.082L246.151 269.046V272.813H249.882V269.082Z' />
      <path d='M241.689 269.082L237.958 269.046V272.813H241.689V269.082Z' />
      <path d='M233.497 269.082L229.766 269.046V272.813H233.497V269.082Z' />
      <path d='M225.306 269.082L221.575 269.046V272.813H225.306V269.082Z' />
      <path d='M217.114 269.082L213.383 269.046V272.813H217.114V269.082Z' />
      <path d='M208.935 269.082L205.204 269.046V272.813H208.935V269.082Z' />
      <path d='M200.743 269.082L197.012 269.046V272.813H200.743V269.082Z' />
      <path d='M192.55 269.082L188.819 269.046V272.813H192.55V269.082Z' />
      <path d='M184.359 269.082L180.628 269.046V272.813H184.359V269.082Z' />
      <path d='M176.167 269.082L172.436 269.046V272.813H176.167V269.082Z' />
      <path d='M167.975 269.082L164.244 269.046V272.813H167.975V269.082Z' />
      <path d='M159.784 269.082L156.053 269.046V272.813H159.784V269.082Z' />
      <path d='M151.604 269.082L147.873 269.046V272.813H151.604V269.082Z' />
      <path d='M143.411 269.082L139.68 269.046V272.813H143.411V269.082Z' />
      <path d='M135.22 269.082L131.489 269.046V272.813H135.22V269.082Z' />
      <path d='M127.028 269.082L123.297 269.046V272.813H127.028V269.082Z' />
      <path d='M118.836 269.082L115.105 269.046V272.813H118.836V269.082Z' />
      <path d='M110.644 269.082L106.913 269.046V272.813H110.644V269.082Z' />
      <path d='M102.453 269.082L98.7217 269.046V272.813H102.453V269.082Z' />
      <path d='M94.2608 269.082L90.5298 269.046V272.813H94.2608V269.082Z' />
      <path d='M315.404 260.937L311.673 260.901V264.668H315.404V260.937Z' />
      <path d='M307.212 260.937L303.481 260.901V264.668H307.212V260.937Z' />
      <path d='M282.637 260.937L278.906 260.901V264.668H282.637V260.937Z' />
      <path d='M274.445 260.937L270.714 260.901V264.668H274.445V260.937Z' />
      <path d='M266.265 260.937L262.534 260.901V264.668H266.265V260.937Z' />
      <path d='M258.073 260.937L254.342 260.901V264.668H258.073V260.937Z' />
      <path d='M249.882 260.937L246.151 260.901V264.668H249.882V260.937Z' />
      <path d='M241.689 260.937L237.958 260.901V264.668H241.689V260.937Z' />
      <path d='M233.497 260.937L229.766 260.901V264.668H233.497V260.937Z' />
      <path d='M225.306 260.937L221.575 260.901V264.668H225.306V260.937Z' />
      <path d='M217.114 260.937L213.383 260.901V264.668H217.114V260.937Z' />
      <path d='M208.935 260.937L205.204 260.901V264.668H208.935V260.937Z' />
      <path d='M200.743 260.937L197.012 260.901V264.668H200.743V260.937Z' />
      <path d='M192.55 260.937L188.819 260.901V264.668H192.55V260.937Z' />
      <path d='M184.359 260.937L180.628 260.901V264.668H184.359V260.937Z' />
      <path d='M176.167 260.937L172.436 260.901V264.668H176.167V260.937Z' />
      <path d='M167.975 260.937L164.244 260.901V264.668H167.975V260.937Z' />
      <path d='M159.784 260.937L156.053 260.901V264.668H159.784V260.937Z' />
      <path d='M151.604 260.937L147.873 260.901V264.668H151.604V260.937Z' />
      <path d='M143.411 260.937L139.68 260.901V264.668H143.411V260.937Z' />
      <path d='M135.22 260.937L131.489 260.901V264.668H135.22V260.937Z' />
      <path d='M127.028 260.937L123.297 260.901V264.668H127.028V260.937Z' />
      <path d='M118.836 260.937L115.105 260.901V264.668H118.836V260.937Z' />
      <path d='M110.644 260.937L106.913 260.901V264.668H110.644V260.937Z' />
      <path d='M102.453 260.937L98.7217 260.901V264.668H102.453V260.937Z' />
      <path d='M94.2608 260.937L90.5298 260.901V264.668H94.2608V260.937Z' />
      <path d='M315.404 252.781L311.673 252.746V256.512H315.404V252.781Z' />
      <path d='M315.404 244.624L311.673 244.589V248.355H315.404V244.624Z' />
      <path d='M274.445 252.781L270.714 252.746V256.512H274.445V252.781Z' />
      <path d='M266.265 252.781L262.534 252.746V256.512H266.265V252.781Z' />
      <path d='M258.073 252.781L254.342 252.746V256.512H258.073V252.781Z' />
      <path d='M249.882 252.781L246.151 252.746V256.512H249.882V252.781Z' />
      <path d='M241.689 252.781L237.958 252.746V256.512H241.689V252.781Z' />
      <path d='M233.497 252.781L229.766 252.746V256.512H233.497V252.781Z' />
      <path d='M225.306 252.781L221.575 252.746V256.512H225.306V252.781Z' />
      <path d='M217.114 252.781L213.383 252.746V256.512H217.114V252.781Z' />
      <path d='M208.935 252.781L205.204 252.746V256.512H208.935V252.781Z' />
      <path d='M200.743 252.781L197.012 252.746V256.512H200.743V252.781Z' />
      <path d='M192.55 252.781L188.819 252.746V256.512H192.55V252.781Z' />
      <path d='M184.359 252.781L180.628 252.746V256.512H184.359V252.781Z' />
      <path d='M176.167 252.781L172.436 252.746V256.512H176.167V252.781Z' />
      <path d='M167.975 252.781L164.244 252.746V256.512H167.975V252.781Z' />
      <path d='M159.784 252.781L156.053 252.746V256.512H159.784V252.781Z' />
      <path d='M151.604 252.781L147.873 252.746V256.512H151.604V252.781Z' />
      <path d='M143.411 252.781L139.68 252.746V256.512H143.411V252.781Z' />
      <path d='M135.22 252.781L131.489 252.746V256.512H135.22V252.781Z' />
      <path d='M127.028 252.781L123.297 252.746V256.512H127.028V252.781Z' />
      <path d='M118.836 252.781L115.105 252.746V256.512H118.836V252.781Z' />
      <path d='M110.644 252.781L106.913 252.746V256.512H110.644V252.781Z' />
      <path d='M102.453 252.781L98.7217 252.746V256.512H102.453V252.781Z' />
      <path d='M94.2608 252.781L90.5298 252.746V256.512H94.2608V252.781Z' />
      <path d='M282.637 244.624L278.906 244.589V248.355H282.637V244.624Z' />
      <path d='M274.445 244.624L270.714 244.589V248.355H274.445V244.624Z' />
      <path d='M266.265 244.624L262.534 244.589V248.355H266.265V244.624Z' />
      <path d='M258.073 244.624L254.342 244.589V248.355H258.073V244.624Z' />
      <path d='M249.882 244.624L246.151 244.589V248.355H249.882V244.624Z' />
      <path d='M241.689 244.624L237.958 244.589V248.355H241.689V244.624Z' />
      <path d='M233.497 244.624L229.766 244.589V248.355H233.497V244.624Z' />
      <path d='M225.306 244.624L221.575 244.589V248.355H225.306V244.624Z' />
      <path d='M217.114 244.624L213.383 244.589V248.355H217.114V244.624Z' />
      <path d='M208.935 244.624L205.204 244.589V248.355H208.935V244.624Z' />
      <path d='M200.743 244.624L197.012 244.589V248.355H200.743V244.624Z' />
      <path d='M192.55 244.624L188.819 244.589V248.355H192.55V244.624Z' />
      <path d='M184.359 244.624L180.628 244.589V248.355H184.359V244.624Z' />
      <path d='M176.167 244.624L172.436 244.589V248.355H176.167V244.624Z' />
      <path d='M167.975 244.624L164.244 244.589V248.355H167.975V244.624Z' />
      <path d='M159.784 244.624L156.053 244.589V248.355H159.784V244.624Z' />
      <path d='M151.604 244.624L147.873 244.589V248.355H151.604V244.624Z' />
      <path d='M143.411 244.624L139.68 244.589V248.355H143.411V244.624Z' />
      <path d='M135.22 244.624L131.489 244.589V248.355H135.22V244.624Z' />
      <path d='M127.028 244.624L123.297 244.589V248.355H127.028V244.624Z' />
      <path d='M118.836 244.624L115.105 244.589V248.355H118.836V244.624Z' />
      <path d='M110.644 244.624L106.913 244.589V248.355H110.644V244.624Z' />
      <path d='M102.453 244.624L98.7217 244.589V248.355H102.453V244.624Z' />
      <path d='M94.2608 244.624L90.5298 244.589V248.355H94.2608V244.624Z' />
      <path d='M307.212 236.479L303.481 236.444V240.21H307.212V236.479Z' />
      <path d='M282.637 236.479L278.906 236.444V240.21H282.637V236.479Z' />
      <path d='M274.445 236.479L270.714 236.444V240.21H274.445V236.479Z' />
      <path d='M266.265 236.479L262.534 236.444V240.21H266.265V236.479Z' />
      <path d='M258.073 236.479L254.342 236.444V240.21H258.073V236.479Z' />
      <path d='M249.882 236.479L246.151 236.444V240.21H249.882V236.479Z' />
      <path d='M233.497 236.479L229.766 236.444V240.21H233.497V236.479Z' />
      <path d='M225.306 236.479L221.575 236.444V240.21H225.306V236.479Z' />
      <path d='M217.114 236.479L213.383 236.444V240.21H217.114V236.479Z' />
      <path d='M208.935 236.479L205.204 236.444V240.21H208.935V236.479Z' />
      <path d='M200.743 236.479L197.012 236.444V240.21H200.743V236.479Z' />
      <path d='M192.55 236.479L188.819 236.444V240.21H192.55V236.479Z' />
      <path d='M184.359 236.479L180.628 236.444V240.21H184.359V236.479Z' />
      <path d='M176.167 236.479L172.436 236.444V240.21H176.167V236.479Z' />
      <path d='M167.975 236.479L164.244 236.444V240.21H167.975V236.479Z' />
      <path d='M159.784 236.479L156.053 236.444V240.21H159.784V236.479Z' />
      <path d='M151.604 236.479L147.873 236.444V240.21H151.604V236.479Z' />
      <path d='M143.411 236.479L139.68 236.444V240.21H143.411V236.479Z' />
      <path d='M135.22 236.479L131.489 236.444V240.21H135.22V236.479Z' />
      <path d='M127.028 236.479L123.297 236.444V240.21H127.028V236.479Z' />
      <path d='M118.836 236.479L115.105 236.444V240.21H118.836V236.479Z' />
      <path d='M110.644 236.479L106.913 236.444V240.21H110.644V236.479Z' />
      <path d='M102.453 236.479L98.7217 236.444V240.21H102.453V236.479Z' />
      <path d='M94.2608 236.479L90.5298 236.444V240.21H94.2608V236.479Z' />
      <path d='M315.404 228.323L311.673 228.288V232.054H315.404V228.323Z' />
      <path d='M282.637 228.323L278.906 228.288V232.054H282.637V228.323Z' />
      <path d='M266.265 228.323L262.534 228.288V232.054H266.265V228.323Z' />
      <path d='M258.073 228.323L254.342 228.288V232.054H258.073V228.323Z' />
      <path d='M249.882 228.323L246.151 228.288V232.054H249.882V228.323Z' />
      <path d='M208.935 228.323L205.204 228.288V232.054H208.935V228.323Z' />
      <path d='M200.743 228.323L197.012 228.288V232.054H200.743V228.323Z' />
      <path d='M176.167 228.323L172.436 228.288V232.054H176.167V228.323Z' />
      <path d='M167.975 228.323L164.244 228.288V232.054H167.975V228.323Z' />
      <path d='M159.784 228.323L156.053 228.288V232.054H159.784V228.323Z' />
      <path d='M151.604 228.323L147.873 228.288V232.054H151.604V228.323Z' />
      <path d='M143.411 228.323L139.68 228.288V232.054H143.411V228.323Z' />
      <path d='M135.22 228.323L131.489 228.288V232.054H135.22V228.323Z' />
      <path d='M127.028 228.323L123.297 228.288V232.054H127.028V228.323Z' />
      <path d='M118.836 228.323L115.105 228.288V232.054H118.836V228.323Z' />
      <path d='M110.644 228.323L106.913 228.288V232.054H110.644V228.323Z' />
      <path d='M110.644 220.167L106.913 220.131V223.898H110.644V220.167Z' />
      <path d='M102.453 228.323L98.7217 228.288V232.054H102.453V228.323Z' />
      <path d='M94.2608 228.323L90.5298 228.288V232.054H94.2608V228.323Z' />
      <path d='M315.404 220.167L311.673 220.131V223.898H315.404V220.167Z' />
      <path d='M307.212 220.167L303.481 220.131V223.898H307.212V220.167Z' />
      <path d='M282.637 220.167L278.906 220.131V223.898H282.637V220.167Z' />
      <path d='M258.073 220.167L254.342 220.131V223.898H258.073V220.167Z' />
      <path d='M249.882 220.167L246.151 220.131V223.898H249.882V220.167Z' />
      <path d='M233.497 220.167L229.766 220.131V223.898H233.497V220.167Z' />
      <path d='M217.114 220.167L213.383 220.131V223.898H217.114V220.167Z' />
      <path d='M208.935 220.167L205.204 220.131V223.898H208.935V220.167Z' />
      <path d='M200.743 220.167L197.012 220.131V223.898H200.743V220.167Z' />
      <path d='M192.55 220.167L188.819 220.131V223.898H192.55V220.167Z' />
      <path d='M184.359 220.167L180.628 220.131V223.898H184.359V220.167Z' />
      <path d='M159.784 220.167L156.053 220.131V223.898H159.784V220.167Z' />
      <path d='M151.604 220.167L147.873 220.131V223.898H151.604V220.167Z' />
      <path d='M143.411 220.167L139.68 220.131V223.898H143.411V220.167Z' />
      <path d='M135.22 220.167L131.489 220.131V223.898H135.22V220.167Z' />
      <path d='M135.22 212.01L131.489 211.975V215.741H135.22V212.01Z' />
      <path d='M127.028 220.167L123.297 220.131V223.898H127.028V220.167Z' />
      <path d='M118.836 220.167L115.105 220.131V223.898H118.836V220.167Z' />
      <path d='M94.2608 220.167L90.5298 220.131V223.898H94.2608V220.167Z' />
      <path d='M315.404 212.01L311.673 211.975V215.741H315.404V212.01Z' />
      <path d='M307.212 212.01L303.481 211.975V215.741H307.212V212.01Z' />
      <path d='M299.021 212.01L295.29 211.975V215.741H299.021V212.01Z' />
      <path d='M290.829 212.01L287.098 211.975V215.741H290.829V212.01Z' />
      <path d='M282.637 212.01L278.906 211.975V215.741H282.637V212.01Z' />
      <path d='M274.445 212.01L270.714 211.975V215.741H274.445V212.01Z' />
      <path d='M249.882 212.01L246.151 211.975V215.741H249.882V212.01Z' />
      <path d='M241.689 212.01L237.958 211.975V215.741H241.689V212.01Z' />
      <path d='M217.114 212.01L213.383 211.975V215.741H217.114V212.01Z' />
      <path d='M208.935 212.01L205.204 211.975V215.741H208.935V212.01Z' />
      <path d='M200.743 212.01L197.012 211.975V215.741H200.743V212.01Z' />
      <path d='M192.55 212.01L188.819 211.975V215.741H192.55V212.01Z' />
      <path d='M184.359 212.01L180.628 211.975V215.741H184.359V212.01Z' />
      <path d='M176.167 212.01L172.436 211.975V215.741H176.167V212.01Z' />
      <path d='M315.404 203.865L311.673 203.83V207.596H315.404V203.865Z' />
      <path d='M307.212 203.865L303.481 203.83V207.596H307.212V203.865Z' />
      <path d='M299.021 203.865L295.29 203.83V207.596H299.021V203.865Z' />
      <path d='M290.829 203.865L287.098 203.83V207.596H290.829V203.865Z' />
      <path d='M282.637 203.865L278.906 203.83V207.596H282.637V203.865Z' />
      <path d='M274.445 203.865L270.714 203.83V207.596H274.445V203.865Z' />
      <path d='M266.265 203.865L262.534 203.83V207.596H266.265V203.865Z' />
      <path d='M249.882 203.865L246.151 203.83V207.596H249.882V203.865Z' />
      <path d='M241.689 203.865L237.958 203.83V207.596H241.689V203.865Z' />
      <path d='M208.935 203.865L205.204 203.83V207.596H208.935V203.865Z' />
      <path d='M200.743 203.865L197.012 203.83V207.596H200.743V203.865Z' />
      <path d='M192.55 203.865L188.819 203.83V207.596H192.55V203.865Z' />
      <path d='M184.359 203.865L180.628 203.83V207.596H184.359V203.865Z' />
      <path d='M176.167 203.865L172.436 203.83V207.596H176.167V203.865Z' />
      <path d='M307.212 195.709L303.481 195.673V199.44H307.212V195.709Z' />
      <path d='M299.021 195.709L295.29 195.673V199.44H299.021V195.709Z' />
      <path d='M290.829 195.709L287.098 195.673V199.44H290.829V195.709Z' />
      <path d='M282.637 195.709L278.906 195.673V199.44H282.637V195.709Z' />
      <path d='M274.445 195.709L270.714 195.673V199.44H274.445V195.709Z' />
      <path d='M266.265 195.709L262.534 195.673V199.44H266.265V195.709Z' />
      <path d='M233.497 195.709L229.766 195.673V199.44H233.497V195.709Z' />
      <path d='M208.935 195.709L205.204 195.673V199.44H208.935V195.709Z' />
      <path d='M200.743 195.709L197.012 195.673V199.44H200.743V195.709Z' />
      <path d='M192.55 195.709L188.819 195.673V199.44H192.55V195.709Z' />
      <path d='M184.359 195.709L180.628 195.673V199.44H184.359V195.709Z' />
      <path d='M176.167 195.709L172.436 195.673V199.44H176.167V195.709Z' />
      <path d='M167.975 195.709L164.244 195.673V199.44H167.975V195.709Z' />
      <path d='M159.784 195.709L156.053 195.673V199.44H159.784V195.709Z' />
      <path d='M151.604 195.709L147.873 195.673V199.44H151.604V195.709Z' />
      <path d='M307.212 187.552L303.481 187.517V191.283H307.212V187.552Z' />
      <path d='M299.021 187.552L295.29 187.517V191.283H299.021V187.552Z' />
      <path d='M290.829 187.552L287.098 187.517V191.283H290.829V187.552Z' />
      <path d='M282.637 187.552L278.906 187.517V191.283H282.637V187.552Z' />
      <path d='M266.265 187.552L262.534 187.517V191.283H266.265V187.552Z' />
      <path d='M249.882 187.552L246.151 187.517V191.283H249.882V187.552Z' />
      <path d='M233.497 187.552L229.766 187.517V191.283H233.497V187.552Z' />
      <path d='M225.306 187.552L221.575 187.517V191.283H225.306V187.552Z' />
      <path d='M208.935 187.552L205.204 187.517V191.283H208.935V187.552Z' />
      <path d='M192.55 187.552L188.819 187.517V191.283H192.55V187.552Z' />
      <path d='M184.359 187.552L180.628 187.517V191.283H184.359V187.552Z' />
      <path d='M176.167 187.552L172.436 187.517V191.283H176.167V187.552Z' />
      <path d='M167.975 187.552L164.244 187.517V191.283H167.975V187.552Z' />
      <path d='M159.784 187.552L156.053 187.517V191.283H159.784V187.552Z' />
      <path d='M151.604 187.552L147.873 187.517V191.283H151.604V187.552Z' />
      <path d='M151.604 187.552L147.873 187.517V191.283H151.604V187.552Z' />
      <path d='M299.021 179.407L295.29 179.372V183.138H299.021V179.407Z' />
      <path d='M290.829 179.407L287.098 179.372V183.138H290.829V179.407Z' />
      <path d='M282.637 179.407L278.906 179.372V183.138H282.637V179.407Z' />
      <path d='M274.445 179.407L270.714 179.372V183.138H274.445V179.407Z' />
      <path d='M266.265 179.407L262.534 179.372V183.138H266.265V179.407Z' />
      <path d='M249.882 179.407L246.151 179.372V183.138H249.882V179.407Z' />
      <path d='M233.497 179.407L229.766 179.372V183.138H233.497V179.407Z' />
      <path d='M225.306 179.407L221.575 179.372V183.138H225.306V179.407Z' />
      <path d='M208.935 179.407L205.204 179.372V183.138H208.935V179.407Z' />
      <path d='M176.167 179.407L172.436 179.372V183.138H176.167V179.407Z' />
      <path d='M167.975 179.407L164.244 179.372V183.138H167.975V179.407Z' />
      <path d='M159.784 179.407L156.053 179.372V183.138H159.784V179.407Z' />
      <path d='M151.604 179.407L147.873 179.372V183.138H151.604V179.407Z' />
      <path d='M249.882 171.251L246.151 171.216V174.982H249.882V171.251Z' />
      <path d='M167.975 171.251L164.244 171.216V174.982H167.975V171.251Z' />
      <path d='M159.784 171.251L156.053 171.216V174.982H159.784V171.251Z' />
      <path d='M151.604 171.251L147.873 171.216V174.982H151.604V171.251Z' />
      <path d='M290.829 163.095L287.098 163.06V166.826H290.829V163.095Z' />
      <path d='M282.637 163.095L278.906 163.06V166.826H282.637V163.095Z' />
      <path d='M274.445 163.095L270.714 163.06V166.826H274.445V163.095Z' />
      <path d='M266.265 163.095L262.534 163.06V166.826H266.265V163.095Z' />
      <path d='M258.073 163.095L254.342 163.06V166.826H258.073V163.095Z' />
      <path d='M184.359 163.095L180.628 163.06V166.826H184.359V163.095Z' />
      <path d='M290.829 154.938L287.098 154.903V158.669H290.829V154.938Z' />
      <path d='M282.637 154.938L278.906 154.903V158.669H282.637V154.938Z' />
      <path d='M274.445 154.938L270.714 154.903V158.669H274.445V154.938Z' />
      <path d='M266.265 154.938L262.534 154.903V158.669H266.265V154.938Z' />
      <path d='M258.073 154.938L254.342 154.903V158.669H258.073V154.938Z' />
      <path d='M241.689 154.938L237.958 154.903V158.669H241.689V154.938Z' />
      <path d='M233.497 154.938L229.766 154.903V158.669H233.497V154.938Z' />
      <path d='M217.114 154.938L213.383 154.903V158.669H217.114V154.938Z' />
      <path d='M208.935 154.938L205.204 154.903V158.669H208.935V154.938Z' />
      <path d='M200.743 154.938L197.012 154.903V158.669H200.743V154.938Z' />
      <path d='M192.55 154.938L188.819 154.903V158.669H192.55V154.938Z' />
      <path d='M184.359 154.938L180.628 154.903V158.669H184.359V154.938Z' />
      <path d='M176.167 154.938L172.436 154.903V158.669H176.167V154.938Z' />
      <path d='M290.829 146.793L287.098 146.758V150.524H290.829V146.793Z' />
      <path d='M258.073 146.793L254.342 146.758V150.524H258.073V146.793Z' />
      <path d='M233.497 146.793L229.766 146.758V150.524H233.497V146.793Z' />
      <path d='M233.497 138.637L229.766 138.602V142.368H233.497V138.637Z' />
      <path d='M225.306 146.793L221.575 146.758V150.524H225.306V146.793Z' />
      <path d='M200.743 146.793L197.012 146.758V150.524H200.743V146.793Z' />
      <path d='M184.359 146.793L180.628 146.758V150.524H184.359V146.793Z' />
      <path d='M176.167 146.793L172.436 146.758V150.524H176.167V146.793Z' />
      <path d='M167.975 146.793L164.244 146.758V150.524H167.975V146.793Z' />
      <path d='M159.784 146.793L156.053 146.758V150.524H159.784V146.793Z' />
      <path d='M299.021 138.637L295.29 138.602V142.368H299.021V138.637Z' />
      <path d='M290.829 138.637L287.098 138.602V142.368H290.829V138.637Z' />
      <path d='M282.637 138.637L278.906 138.602V142.368H282.637V138.637Z' />
      <path d='M274.445 138.637L270.714 138.602V142.368H274.445V138.637Z' />
      <path d='M266.265 138.637L262.534 138.602V142.368H266.265V138.637Z' />
      <path d='M266.265 130.481L262.534 130.445V134.212H266.265V130.481Z' />
      <path d='M258.073 138.637L254.342 138.602V142.368H258.073V138.637Z' />
      <path d='M249.882 138.637L246.151 138.602V142.368H249.882V138.637Z' />
      <path d='M225.306 138.637L221.575 138.602V142.368H225.306V138.637Z' />
      <path d='M217.114 138.637L213.383 138.602V142.368H217.114V138.637Z' />
      <path d='M200.743 138.637L197.012 138.602V142.368H200.743V138.637Z' />
      <path d='M167.975 138.637L164.244 138.602V142.368H167.975V138.637Z' />
      <path d='M159.784 138.637L156.053 138.602V142.368H159.784V138.637Z' />
      <path d='M290.829 130.481L287.098 130.445V134.212H290.829V130.481Z' />
      <path d='M282.637 130.481L278.906 130.445V134.212H282.637V130.481Z' />
      <path d='M274.445 130.481L270.714 130.445V134.212H274.445V130.481Z' />
      <path d='M176.167 130.481L172.436 130.445V134.212H176.167V130.481Z' />
      <path d='M167.975 130.481L164.244 130.445V134.212H167.975V130.481Z' />
      <path d='M299.021 122.336L295.29 122.301V126.067H299.021V122.336Z' />
      <path d='M290.829 122.336L287.098 122.301V126.067H290.829V122.336Z' />
      <path d='M282.637 122.336L278.906 122.301V126.067H282.637V122.336Z' />
      <path d='M274.445 122.336L270.714 122.301V126.067H274.445V122.336Z' />
      <path d='M249.882 122.336L246.151 122.301V126.067H249.882V122.336Z' />
      <path d='M241.689 122.336L237.958 122.301V126.067H241.689V122.336Z' />
      <path d='M208.935 122.336L205.204 122.301V126.067H208.935V122.336Z' />
      <path d='M192.55 122.336L188.819 122.301V126.067H192.55V122.336Z' />
      <path d='M184.359 122.336L180.628 122.301V126.067H184.359V122.336Z' />
      <path d='M307.212 114.179L303.481 114.144V117.91H307.212V114.179Z' />
      <path d='M299.021 114.179L295.29 114.144V117.91H299.021V114.179Z' />
      <path d='M290.829 114.179L287.098 114.144V117.91H290.829V114.179Z' />
      <path d='M282.637 114.179L278.906 114.144V117.91H282.637V114.179Z' />
      <path d='M274.445 114.179L270.714 114.144V117.91H274.445V114.179Z' />
      <path d='M241.689 114.179L237.958 114.144V117.91H241.689V114.179Z' />
      <path d='M225.306 114.179L221.575 114.144V117.91H225.306V114.179Z' />
      <path d='M192.55 114.179L188.819 114.144V117.91H192.55V114.179Z' />
      <path d='M307.212 106.023L303.481 105.988V109.754H307.212V106.023Z' />
      <path d='M299.021 106.023L295.29 105.988V109.754H299.021V106.023Z' />
      <path d='M290.829 106.023L287.098 105.988V109.754H290.829V106.023Z' />
      <path d='M282.637 106.023L278.906 105.988V109.754H282.637V106.023Z' />
      <path d='M274.445 106.023L270.714 105.988V109.754H274.445V106.023Z' />
      <path d='M266.265 106.023L262.534 105.988V109.754H266.265V106.023Z' />
      <path d='M258.073 106.023L254.342 105.988V109.754H258.073V106.023Z' />
      <path d='M225.306 106.023L221.575 105.988V109.754H225.306V106.023Z' />
      <path d='M233.497 106.023L229.766 105.988V109.754H233.497V106.023Z' />
      <path d='M225.306 81.5651L221.575 81.5298V85.2961H225.306V81.5651Z' />
      <path d='M217.114 106.023L213.383 105.988V109.754H217.114V106.023Z' />
      <path d='M192.55 106.023L188.819 105.988V109.754H192.55V106.023Z' />
      <path d='M307.212 97.8781L303.481 97.8428V101.609H307.212V97.8781Z' />
      <path d='M299.021 97.8781L295.29 97.8428V101.609H299.021V97.8781Z' />
      <path d='M290.829 97.8781L287.098 97.8428V101.609H290.829V97.8781Z' />
      <path d='M282.637 97.8781L278.906 97.8428V101.609H282.637V97.8781Z' />
      <path d='M274.445 97.8781L270.714 97.8428V101.609H274.445V97.8781Z' />
      <path d='M266.265 97.8781L262.534 97.8428V101.609H266.265V97.8781Z' />
      <path d='M258.073 97.8781L254.342 97.8428V101.609H258.073V97.8781Z' />
      <path d='M249.882 97.8781L246.151 97.8428V101.609H249.882V97.8781Z' />
      <path d='M217.114 97.8781L213.383 97.8428V101.609H217.114V97.8781Z' />
      <path d='M315.404 89.7218L311.673 89.6865V93.4529H315.404V89.7218Z' />
      <path d='M307.212 89.7218L303.481 89.6865V93.4529H307.212V89.7218Z' />
      <path d='M299.021 89.7218L295.29 89.6865V93.4529H299.021V89.7218Z' />
      <path d='M290.829 89.7218L287.098 89.6865V93.4529H290.829V89.7218Z' />
      <path d='M282.637 89.7218L278.906 89.6865V93.4529H282.637V89.7218Z' />
      <path d='M274.445 89.7218L270.714 89.6865V93.4529H274.445V89.7218Z' />
      <path d='M266.265 89.7218L262.534 89.6865V93.4529H266.265V89.7218Z' />
      <path d='M258.073 89.7218L254.342 89.6865V93.4529H258.073V89.7218Z' />
      <path d='M249.882 89.7218L246.151 89.6865V93.4529H249.882V89.7218Z' />
      <path d='M315.404 81.5651L311.673 81.5298V85.2961H315.404V81.5651Z' />
      <path d='M307.212 81.5651L303.481 81.5298V85.2961H307.212V81.5651Z' />
      <path d='M299.021 81.5651L295.29 81.5298V85.2961H299.021V81.5651Z' />
      <path d='M290.829 81.5651L287.098 81.5298V85.2961H290.829V81.5651Z' />
      <path d='M282.637 81.5651L278.906 81.5298V85.2961H282.637V81.5651Z' />
      <path d='M274.445 81.5651L270.714 81.5298V85.2961H274.445V81.5651Z' />
      <path d='M266.265 81.5651L262.534 81.5298V85.2961H266.265V81.5651Z' />
      <path d='M258.073 81.5651L254.342 81.5298V85.2961H258.073V81.5651Z' />
      <path d='M249.882 81.5651L246.151 81.5298V85.2961H249.882V81.5651Z' />
      <path d='M241.689 81.5651L237.958 81.5298V85.2961H241.689V81.5651Z' />
      <path d='M315.404 73.4093L311.673 73.374V77.1404H315.404V73.4093Z' />
      <path d='M307.212 73.4093L303.481 73.374V77.1404H307.212V73.4093Z' />
      <path d='M299.021 73.4093L295.29 73.374V77.1404H299.021V73.4093Z' />
      <path d='M290.829 73.4093L287.098 73.374V77.1404H290.829V73.4093Z' />
      <path d='M258.073 73.4093L254.342 73.374V77.1404H258.073V73.4093Z' />
      <path d='M249.882 73.4093L246.151 73.374V77.1404H249.882V73.4093Z' />
      <path d='M241.689 73.4093L237.958 73.374V77.1404H241.689V73.4093Z' />
      <path d='M315.404 65.2638L311.673 65.2285V68.9949H315.404V65.2638Z' />
      <path d='M307.212 65.2638L303.481 65.2285V68.9949H307.212V65.2638Z' />
      <path d='M290.829 65.2638L287.098 65.2285V68.9949H290.829V65.2638Z' />
      <path d='M282.637 65.2638L278.906 65.2285V68.9949H282.637V65.2638Z' />
      <path d='M274.445 65.2638L270.714 65.2285V68.9949H274.445V65.2638Z' />
      <path d='M266.265 65.2638L262.534 65.2285V68.9949H266.265V65.2638Z' />
      <path d='M258.073 65.2638L254.342 65.2285V68.9949H258.073V65.2638Z' />
      <path d='M249.882 65.2638L246.151 65.2285V68.9949H249.882V65.2638Z' />
      <path d='M315.404 57.1076L311.673 57.0723V60.8386H315.404V57.1076Z' />
      <path d='M307.212 57.1076L303.481 57.0723V60.8386H307.212V57.1076Z' />
      <path d='M299.021 57.1076L295.29 57.0723V60.8386H299.021V57.1076Z' />
      <path d='M299.021 65.2638L295.29 65.2285V68.9949H299.021V65.2638Z' />
      <path d='M290.829 57.1076L287.098 57.0723V60.8386H290.829V57.1076Z' />
      <path d='M282.637 57.1076L278.906 57.0723V60.8386H282.637V57.1076Z' />
      <path d='M274.445 57.1076L270.714 57.0723V60.8386H274.445V57.1076Z' />
      <path d='M266.265 57.1076L262.534 57.0723V60.8386H266.265V57.1076Z' />
      <path d='M249.882 57.1076L246.151 57.0723V60.8386H249.882V57.1076Z' />
      <path d='M315.404 48.9513L311.673 48.916V52.6823H315.404V48.9513Z' />
      <path d='M307.212 48.9513L303.481 48.916V52.6823H307.212V48.9513Z' />
      <path d='M299.021 48.9513L295.29 48.916V52.6823H299.021V48.9513Z' />
      <path d='M290.829 48.9513L287.098 48.916V52.6823H290.829V48.9513Z' />
      <path d='M282.637 48.9513L278.906 48.916V52.6823H282.637V48.9513Z' />
      <path d='M274.445 48.9513L270.714 48.916V52.6823H274.445V48.9513Z' />
      <path d='M266.265 48.9513L262.534 48.916V52.6823H266.265V48.9513Z' />
      <path d='M315.404 40.8063L311.673 40.771V44.5373H315.404V40.8063Z' />
      <path d='M307.212 40.8063L303.481 40.771V44.5373H307.212V40.8063Z' />
      <path d='M299.021 40.8063L295.29 40.771V44.5373H299.021V40.8063Z' />
      <path d='M290.829 40.8063L287.098 40.771V44.5373H290.829V40.8063Z' />
      <path d='M282.637 40.8063L278.906 40.771V44.5373H282.637V40.8063Z' />
      <path d='M274.445 40.8063L270.714 40.771V44.5373H274.445V40.8063Z' />
      <path d='M266.265 40.8063L262.534 40.771V44.5373H266.265V40.8063Z' />
      <path d='M315.404 32.6501L311.673 32.6147V36.3811H315.404V32.6501Z' />
      <path d='M307.212 32.6501L303.481 32.6147V36.3811H307.212V32.6501Z' />
      <path d='M299.021 32.6501L295.29 32.6147V36.3811H299.021V32.6501Z' />
      <path d='M290.829 32.6501L287.098 32.6147V36.3811H290.829V32.6501Z' />
      <path d='M282.637 32.6501L278.906 32.6147V36.3811H282.637V32.6501Z' />
      <path d='M274.445 32.6501L270.714 32.6147V36.3811H274.445V32.6501Z' />
      <path d='M315.404 24.4938L311.673 24.4585V28.2248H315.404V24.4938Z' />
      <path d='M307.212 24.4938L303.481 24.4585V28.2248H307.212V24.4938Z' />
      <path d='M299.021 24.4938L295.29 24.4585V28.2248H299.021V24.4938Z' />
      <path d='M290.829 24.4938L287.098 24.4585V28.2248H290.829V24.4938Z' />
      <path d='M315.404 16.3371L311.673 16.3018V20.0681H315.404V16.3371Z' />
      <path d='M307.212 16.3371L303.481 16.3018V20.0681H307.212V16.3371Z' />
      <path d='M299.021 16.3371L295.29 16.3018V20.0681H299.021V16.3371Z' />
      <path d='M331.775 65.2638L328.044 65.2285V68.9949H331.775V65.2638Z' />
      <path d='M323.596 65.2638L319.865 65.2285V68.9949H323.596V65.2638Z' />
      <path d='M331.775 57.1076L328.044 57.0723V60.8386H331.775V57.1076Z' />
      <path d='M323.596 57.1076L319.865 57.0723V60.8386H323.596V57.1076Z' />
      <path d='M331.775 48.9513L328.044 48.916V52.6823H331.775V48.9513Z' />
      <path d='M323.596 48.9513L319.865 48.916V52.6823H323.596V48.9513Z' />
      <path d='M339.967 40.8063L336.236 40.771V44.5373H339.967V40.8063Z' />
      <path d='M339.967 57.1076L336.236 57.0723V60.8386H339.967V57.1076Z' />
      <path d='M331.775 40.8063L328.044 40.771V44.5373H331.775V40.8063Z' />
      <path d='M323.596 40.8063L319.865 40.771V44.5373H323.596V40.8063Z' />
      <path d='M339.967 32.6501L336.236 32.6147V36.3811H339.967V32.6501Z' />
      <path d='M331.775 32.6501L328.044 32.6147V36.3811H331.775V32.6501Z' />
      <path d='M323.596 32.6501L319.865 32.6147V36.3811H323.596V32.6501Z' />
      <path d='M339.967 24.4938L336.236 24.4585V28.2248H339.967V24.4938Z' />
      <path d='M331.775 24.4938L328.044 24.4585V28.2248H331.775V24.4938Z' />
      <path d='M323.596 24.4938L319.865 24.4585V28.2248H323.596V24.4938Z' />
      <path d='M331.775 16.3371L328.044 16.3018V20.0681H331.775V16.3371Z' />
      <path d='M323.596 16.3371L319.865 16.3018V20.0681H323.596V16.3371Z' />
      <path d='M323.596 81.5651L319.865 81.5298V85.2961H323.596V81.5651Z' />
      <path d='M323.596 73.4093L319.865 73.374V77.1404H323.596V73.4093Z' />
      <path d='M348.159 40.8063L344.428 40.771V44.5373H348.159V40.8063Z' />
      <path d='M348.159 32.6501L344.428 32.6147V36.3811H348.159V32.6501Z' />
      <path d='M348.159 24.4938L344.428 24.4585V28.2248H348.159V24.4938Z' />
      <path d='M372.735 358.768L369.004 358.732V362.499H372.735V358.768Z' />
      <path d='M348.159 252.781L344.428 252.746V256.512H348.159V252.781Z' />
      <path d='M331.775 252.781L328.044 252.746V256.512H331.775V252.781Z' />
      <path d='M323.596 252.781L319.865 252.746V256.512H323.596V252.781Z' />
      <path d='M348.159 244.624L344.428 244.589V248.355H348.159V244.624Z' />
      <path d='M339.967 244.624L336.236 244.589V248.355H339.967V244.624Z' />
      <path d='M331.775 244.624L328.044 244.589V248.355H331.775V244.624Z' />
      <path d='M323.596 244.624L319.865 244.589V248.355H323.596V244.624Z' />
      <path d='M348.159 236.479L344.428 236.444V240.21H348.159V236.479Z' />
      <path d='M339.967 236.479L336.236 236.444V240.21H339.967V236.479Z' />
      <path d='M331.775 236.479L328.044 236.444V240.21H331.775V236.479Z' />
      <path d='M323.596 236.479L319.865 236.444V240.21H323.596V236.479Z' />
      <path d='M331.775 228.323L328.044 228.288V232.054H331.775V228.323Z' />
      <path d='M323.596 228.323L319.865 228.288V232.054H323.596V228.323Z' />
      <path d='M331.775 220.167L328.044 220.131V223.898H331.775V220.167Z' />
      <path d='M323.596 220.167L319.865 220.131V223.898H323.596V220.167Z' />
      <path d='M331.775 212.01L328.044 211.975V215.741H331.775V212.01Z' />
      <path d='M323.596 212.01L319.865 211.975V215.741H323.596V212.01Z' />
      <path d='M323.596 203.865L319.865 203.83V207.596H323.596V203.865Z' />
      <path d='M299.021 383.226L295.29 383.19V386.957H299.021V383.226Z' />
      <path d='M290.829 383.226L287.098 383.19V386.957H290.829V383.226Z' />
      <path d='M348.159 375.08L344.428 375.045V378.811H348.159V375.08Z' />
      <path d='M339.967 375.08L336.236 375.045V378.811H339.967V375.08Z' />
      <path d='M307.212 375.08L303.481 375.045V378.811H307.212V375.08Z' />
      <path d='M299.021 375.08L295.29 375.045V378.811H299.021V375.08Z' />
    </g>
  );
};
export default CanadaMap;
