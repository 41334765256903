import React from "react";
import { useDelayedHover } from "../../../hooks/useDelayedHover";
import { Countries } from "../BuildersMap";

interface Props {
  className?: string;
  onHover: (
    country: Countries | undefined
  ) => React.MouseEventHandler<SVGGElement>;
}

const IndiaMap: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { handleMouseEnter, handleMouseOut } = useDelayedHover(
    props.onHover,
    600
  );

  return (
    <g
      id='india'
      onMouseEnter={handleMouseEnter(Countries.India)}
      onMouseOut={handleMouseOut}
      fill='url(#paint0_linear_3616_2988)'>
      <path d='M814.996 513.682L811.265 513.646V517.413H814.996V513.682Z' />
      <path d='M814.996 505.525L811.265 505.49V509.256H814.996V505.525Z' />
      <path d='M823.188 497.369L819.457 497.333V501.1H823.188V497.369Z' />
      <path d='M823.188 505.525L819.457 505.49V509.256H823.188V505.525Z' />
      <path d='M814.996 497.369L811.265 497.333V501.1H814.996V497.369Z' />
      <path d='M806.804 497.369L803.073 497.333V501.1H806.804V497.369Z' />
      <path d='M823.188 489.224L819.457 489.188V492.955H823.188V489.224Z' />
      <path d='M814.996 489.224L811.265 489.188V492.955H814.996V489.224Z' />
      <path d='M806.804 489.224L803.073 489.188V492.955H806.804V489.224Z' />
      <path d='M831.38 481.068L827.649 481.032V484.799H831.38V481.068Z' />
      <path d='M823.188 481.068L819.457 481.032V484.799H823.188V481.068Z' />
      <path d='M814.996 481.068L811.265 481.032V484.799H814.996V481.068Z' />
      <path d='M806.804 481.068L803.073 481.032V484.799H806.804V481.068Z' />

      <path d='M855.956 456.609L852.225 456.574V460.34H855.956V456.609Z' />
      <path d='M855.956 464.754L852.225 464.719V468.485H855.956V464.754Z' />
      <path d='M839.572 472.911L835.841 472.876V476.642H839.572V472.911Z' />
      <path d='M831.38 472.911L827.649 472.876V476.642H831.38V472.911Z' />
      <path d='M823.188 472.911L819.457 472.876V476.642H823.188V472.911Z' />
      <path d='M814.996 472.911L811.265 472.876V476.642H814.996V472.911Z' />
      <path d='M806.804 472.911L803.073 472.876V476.642H806.804V472.911Z' />
      <path d='M798.625 472.911L794.894 472.876V476.642H798.625V472.911Z' />
      <path d='M864.147 464.755L860.416 464.719V468.486H864.147V464.755Z' />
      <path d='M847.763 464.755L844.032 464.719V468.486H847.763V464.755Z' />
      <path d='M839.572 464.755L835.841 464.719V468.486H839.572V464.755Z' />
      <path d='M831.38 464.755L827.649 464.719V468.486H831.38V464.755Z' />
      <path d='M823.188 464.755L819.457 464.719V468.486H823.188V464.755Z' />
      <path d='M814.996 464.755L811.265 464.719V468.486H814.996V464.755Z' />
      <path d='M806.804 464.755L803.073 464.719V468.486H806.804V464.755Z' />
      <path d='M798.625 464.755L794.894 464.719V468.486H798.625V464.755Z' />
      <path d='M790.433 464.755L786.702 464.719V468.486H790.433V464.755Z' />
      <path d='M864.147 456.61L860.416 456.575V460.341H864.147V456.61Z' />
      <path d='M847.763 456.61L844.032 456.575V460.341H847.763V456.61Z' />
      <path d='M839.572 456.61L835.841 456.575V460.341H839.572V456.61Z' />
      <path d='M831.38 456.61L827.649 456.575V460.341H831.38V456.61Z' />
      <path d='M823.188 456.61L819.457 456.575V460.341H823.188V456.61Z' />
      <path d='M814.996 456.61L811.265 456.575V460.341H814.996V456.61Z' />
      <path d='M806.804 456.61L803.073 456.575V460.341H806.804V456.61Z' />
      <path d='M798.625 456.61L794.894 456.575V460.341H798.625V456.61Z' />
      <path d='M790.433 456.61L786.702 456.575V460.341H790.433V456.61Z' />
      <path d='M872.327 448.453L868.596 448.418V452.184H872.327V448.453Z' />
      <path d='M864.147 448.453L860.416 448.418V452.184H864.147V448.453Z' />
      <path d='M855.955 448.453L852.224 448.418V452.184H855.955V448.453Z' />
      <path d='M839.572 448.453L835.841 448.418V452.184H839.572V448.453Z' />
      <path d='M847.763 448.453L844.032 448.418V452.184H847.763V448.453Z' />
      <path d='M831.38 448.453L827.649 448.418V452.184H831.38V448.453Z' />
      <path d='M823.188 448.453L819.457 448.418V452.184H823.188V448.453Z' />
      <path d='M814.996 448.453L811.265 448.418V452.184H814.996V448.453Z' />
      <path d='M806.804 448.453L803.073 448.418V452.184H806.804V448.453Z' />
      <path d='M798.625 448.453L794.894 448.418V452.184H798.625V448.453Z' />
      <path d='M823.188 440.297L819.457 440.262V444.028H823.188V440.297Z' />
      <path d='M814.996 440.297L811.265 440.262V444.028H814.996V440.297Z' />
      <path d='M806.804 440.297L803.073 440.262V444.028H806.804V440.297Z' />
      <path d='M814.996 432.152L811.265 432.117V435.884H814.996V432.152Z' />
      <path d='M806.804 432.152L803.073 432.117V435.884H806.804V432.152Z' />
      <path d='M814.996 423.996L811.265 423.96V427.727H814.996V423.996Z' />
      <path d='M806.804 423.996L803.073 423.96V427.727H806.804V423.996Z' />

    </g>
  );
};
export default IndiaMap;
