import React from "react";
import { Countries } from "../BuildersMap";
import { useDelayedHover } from "../../../hooks/useDelayedHover";

interface Props {
  className?: string;
  onHover: (
    country: Countries | undefined
  ) => React.MouseEventHandler<SVGGElement>;
}

const ArgentinaMap: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { handleMouseEnter, handleMouseOut } = useDelayedHover(
    props.onHover,
    600
  );

  return (
    <g
      id={Countries.Argentina}
      onMouseEnter={handleMouseEnter(Countries.Argentina)}
      onMouseOut={handleMouseOut}
      fill='url(#paint0_linear_3616_2988)'>
      <path d='M339.968 758.27L336.237 758.234V762.001H339.968V758.27Z' />
      <path d='M331.777 758.27L328.046 758.234V762.001H331.777V758.27Z' />
      <path d='M331.776 750.114L328.045 750.079V753.845H331.776V750.114Z' />
      <path d='M331.776 741.957L328.045 741.921V745.688H331.776V741.957Z' />
      <path d='M323.596 741.957L319.865 741.921V745.688H323.596V741.957Z' />
      <path d='M331.776 733.813L328.045 733.778V737.544H331.776V733.813Z' />
      <path d='M323.596 733.813L319.865 733.778V737.544H323.596V733.813Z' />
      <path d='M331.776 725.656L328.045 725.621V729.387H331.776V725.656Z' />
      <path d='M323.596 725.656L319.865 725.621V729.387H323.596V725.656Z' />
      <path d='M339.968 717.5L336.237 717.465V721.231H339.968V717.5Z' />
      <path d='M331.776 717.5L328.045 717.465V721.231H331.776V717.5Z' />
      <path d='M323.596 717.5L319.865 717.465V721.231H323.596V717.5Z' />
      <path d='M339.968 709.356L336.237 709.32V713.087H339.968V709.356Z' />
      <path d='M331.776 709.356L328.045 709.32V713.087H331.776V709.356Z' />
      <path d='M323.596 709.356L319.865 709.32V713.087H323.596V709.356Z' />
      <path d='M339.968 701.198L336.237 701.163V704.929H339.968V701.198Z' />
      <path d='M331.776 701.198L328.045 701.163V704.929H331.776V701.198Z' />
      <path d='M323.596 701.198L319.865 701.163V704.929H323.596V701.198Z' />
      <path d='M339.968 693.042L336.237 693.007V696.773H339.968V693.042Z' />
      <path d='M331.776 693.042L328.045 693.007V696.773H331.776V693.042Z' />
      <path d='M323.596 693.042L319.865 693.007V696.773H323.596V693.042Z' />
      <path d='M348.159 684.897L344.428 684.862V688.628H348.159V684.897Z' />
      <path d='M339.968 684.897L336.237 684.862V688.628H339.968V684.897Z' />
      <path d='M331.776 684.897L328.045 684.862V688.628H331.776V684.897Z' />
      <path d='M323.596 684.897L319.865 684.862V688.628H323.596V684.897Z' />
      <path d='M364.543 676.741L360.812 676.706V680.472H364.543V676.741Z' />
      <path d='M356.351 676.741L352.62 676.706V680.472H356.351V676.741Z' />
      <path d='M348.159 676.741L344.428 676.706V680.472H348.159V676.741Z' />
      <path d='M339.968 676.741L336.237 676.706V680.472H339.968V676.741Z' />
      <path d='M331.776 676.741L328.045 676.706V680.472H331.776V676.741Z' />
      <path d='M323.596 676.741L319.865 676.706V680.472H323.596V676.741Z' />
      <path d='M364.543 668.585L360.812 668.549V672.316H364.543V668.585Z' />
      <path d='M356.351 668.585L352.62 668.549V672.316H356.351V668.585Z' />
      <path d='M348.159 668.585L344.428 668.549V672.316H348.159V668.585Z' />
      <path d='M339.968 668.585L336.237 668.549V672.316H339.968V668.585Z' />
      <path d='M331.776 668.585L328.045 668.549V672.316H331.776V668.585Z' />
      <path d='M364.543 660.428L360.812 660.393V664.159H364.543V660.428Z' />
      <path d='M356.351 660.428L352.62 660.393V664.159H356.351V660.428Z' />
      <path d='M348.159 660.428L344.428 660.393V664.159H348.159V660.428Z' />
      <path d='M339.968 660.428L336.237 660.393V664.159H339.968V660.428Z' />
      <path d='M331.776 660.428L328.045 660.393V664.159H331.776V660.428Z' />
      <path d='M364.543 652.283L360.812 652.248V656.014H364.543V652.283Z' />
      <path d='M356.351 652.283L352.62 652.248V656.014H356.351V652.283Z' />
      <path d='M348.159 652.283L344.428 652.248V656.014H348.159V652.283Z' />
      <path d='M339.968 652.283L336.237 652.248V656.014H339.968V652.283Z' />
      <path d='M331.776 652.283L328.045 652.248V656.014H331.776V652.283Z' />
      <path d='M364.543 644.127L360.812 644.091V647.858H364.543V644.127Z' />
      <path d='M356.351 644.127L352.62 644.091V647.858H356.351V644.127Z' />
      <path d='M348.159 644.127L344.428 644.091V647.858H348.159V644.127Z' />
      <path d='M339.968 644.127L336.237 644.091V647.858H339.968V644.127Z' />
      <path d='M331.776 644.127L328.045 644.091V647.858H331.776V644.127Z' />
      <path d='M372.734 635.97L369.003 635.935V639.701H372.734V635.97Z' />
      <path d='M380.903 627.802L377.172 627.767V631.533H380.903V627.802Z' />
      <path d='M364.543 635.97L360.812 635.935V639.701H364.543V635.97Z' />
      <path d='M356.351 635.97L352.62 635.935V639.701H356.351V635.97Z' />
      <path d='M348.159 635.97L344.428 635.935V639.701H348.159V635.97Z' />
      <path d='M339.968 635.97L336.237 635.935V639.701H339.968V635.97Z' />
      <path d='M331.776 635.97L328.045 635.935V639.701H331.776V635.97Z' />
      <path d='M364.543 627.826L360.812 627.791V631.557H364.543V627.826Z' />
      <path d='M356.351 627.826L352.62 627.791V631.557H356.351V627.826Z' />
      <path d='M348.159 627.826L344.428 627.791V631.557H348.159V627.826Z' />
      <path d='M339.968 627.826L336.237 627.791V631.557H339.968V627.826Z' />
      <path d='M356.351 619.669L352.62 619.634V623.4H356.351V619.669Z' />
      <path d='M348.159 619.669L344.428 619.634V623.4H348.159V619.669Z' />
      <path d='M339.968 619.669L336.237 619.634V623.4H339.968V619.669Z' />
    </g>
  );
};
export default ArgentinaMap;
