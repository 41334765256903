/** @format */

import { useState } from "react";

const useGoogle = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const generateToken = async (): Promise<{ token: string } | undefined> => {
    setLoading(true);

    return await fetch(`${process.env.REACT_APP_CLOUDFLARE_GOOGLE_OAUTH}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        setLoading(false);
        if (res.status === 200) {
          return (await res.json()) as { token: string };
        }
        return undefined;
      })
      .catch(() => {
        setLoading(false);
        return undefined;
      });
  };

  const uploadFileToDrive = async (
    file: File,
    metadata: { name: string }
  ): Promise<string | undefined> => {
    setLoading(true);
    const gAuth = await generateToken();
    if (!gAuth) return;

    const formData = new FormData();
    const parent = process.env.REACT_APP_GOOGLE_DRIVE_FOLDER;
    formData.append(
      "metadata",
      new Blob(
        [
          JSON.stringify({
            name: metadata.name,
            parents: [parent],
          }),
        ],
        { type: "application/json" }
      )
    );

    formData.append("file", file);
    return await fetch(`${process.env.REACT_APP_GOOGLE_DRIVE_API}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${gAuth.token}`,
      },
      body: formData,
    })
      .then(async (res) => {
        setLoading(false);
        if (res.ok) {
          const response = await res.json();
          if (response?.id) {
            return `https://drive.google.com/file/d/${response.id}/view`;
          }
          return;
        } else {
          console.error("Failed to upload file", res.status, res.statusText);
          return undefined;
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("Error uploading file", err);
        return undefined;
      });
  };

  return { loading, generateToken, uploadFileToDrive };
};

export default useGoogle;
